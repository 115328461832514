import React, { useState, useRef, useEffect } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker, DatePicker} from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';
import 'rsuite/DatePicker/styles/index.css';

function AddNewCompanyProfile() {
    const [tabName, setTabName] = useState('Add New Company Profile');

    useEffect(() => {
      document.title = tabName;
    }, [tabName]);

// DATEPICKER
const [date, setDate] = useState(new Date());

// handle onChange event of the dropdown
const handleChange = (value) => {
  setDate(value);
};

function CustomCaretIcon() {
    return <i className='icon-calendar'></i>;
}

// FILE UPLOAD
const [fileName, setFileName] = useState("");
const fileInputRef = useRef(null);

const handleFileChange = (event) => {
  setFileName(event.target.files[0].name);
};

const handleClick = () => {
  fileInputRef.current.click();
};
// END FILE UPLOAD

const data = ['India', 'United State', 'Australia', 'Canada', 'Israel', 'Germany'].map(
    item => ({ label: item, value: item })
);

const data2 = ['+91', '+92', '+93'].map(
    item => ({ label: item, value: item })
);


  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>Add New Company Profile</div>
        </div>

        <div className=''>
            <ul className='add_company_btn_list'>
                <li><a href='#' className='btn_disabled'>Uncovered</a></li>
                <li><a href='#' className='btn_unpublished' style={{display:'none'}}>Unpublished</a></li>
                <li><a href='#' className='btn_published' style={{display:'none'}}>Published</a></li>
            </ul>
        </div>
    </div>
    
    <div className="container-fluid content pb-4">
      <div className='company_details_form'>
        <h2>Company Details</h2>

        <div className='form_details_group mt-3'>
            <div className='row'>
                <div className='col-sm-6 col-lg-4'>
                    <label className='label_form'>Company Name<span className='star_red'>*</span></label>
                    <input type='text' className='form-control' placeholder='Name' />
                </div>

                <div className='col-sm-6 col-lg-4'>
                    <label className='label_form'>Address<span className='star_red'>*</span></label>
                    <input type='text' className='form-control' placeholder='Address' />
                </div>

                <div className='col-sm-6 col-lg-2'>
                    <label className='label_form'>City<span className='star_red'>*</span></label>
                    <input type='text' className='form-control' placeholder='City' />
                </div>

                <div className='col-sm-6 col-lg-2'>
                    <label className='label_form'>State<span className='star_red'>*</span></label>
                    <input type='text' className='form-control' placeholder='State' />
                </div>
            </div>
        </div>

        <div className='form_details_group mt-3'>
            <div className='row'>
                <div className='col-sm-6 col-lg-2'>
                    <label className='label_form'>No. of Employees<span className='star_red'>*</span></label>
                    <input type='number' className='form-control' placeholder='Total Employee' />
                </div>

                <div className='col-sm-6 col-lg-3'>
                    <label className='label_form'>Phone No.</label>
                    <div className="input-group">                    
                    <span className="input-group-text">
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{ width: 80 }}
                            placeholder="+91"
                            />
                    </span>
                    <input type="text" className="form-control" placeholder="Phone" />
                    </div>
                </div>

                <div className='col-sm-6 col-lg-4'>
                    <label className='label_form'>Website URL</label>
                    <input type='text' className='form-control' placeholder='Web URL' />
                </div>

                <div className='col-sm-6 col-lg-3'>
                    <label className='label_form'>Logo</label>
                    <div className="input-group file_input_group">
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        name="img[]"
                    />
                    <input
                        type="text"
                        className="form-control"
                        placeholder="No File Choosen"
                        value={fileName}
                        readOnly
                    />
                        
                        <button
                        className="btn_browse btn"
                        type="button"
                        onClick={handleClick}
                        >Browse</button> 
                    </div>
                </div>
            </div>
        </div>

        <div className='form_details_group mt-3'>
            <div className='row'>
                <div className='col-sm-12'>
                    <label className='label_form'>Company Business Summary<span className='star_red'>*</span></label>
                    <textarea rows={2} className='form-control'></textarea>
                </div>
            </div>
        </div>

        <div className='mt-4'>
            <h2>Stock/Report Details</h2>

            <div className='form_details_group mt-3'>
                <div className='row'>
                    <div className='col-sm-6 col-lg-2'>
                        <label className='label_form'>Main Ticker<span className='star_red'>*</span></label>
                        <input type='text' className='form-control' placeholder='Main Ticker' />
                    </div>

                    <div className='col-sm-6 col-lg-2'>
                        <label className='label_form'>Company Symbol<span className='star_red'>*</span></label>
                        <input type='text' className='form-control' placeholder='Ticker' />
                    </div>

                    <div className='col-sm-6 col-lg-2'>
                        <label className='label_form'>Listed Exchange<span className='star_red'>*</span></label>
                        <input type='text' className='form-control' placeholder='Exchange' />
                    </div>

                    <div className='col-sm-6 col-lg-2'>
                        <label className='label_form'>Exchange Country<span className='star_red'>*</span></label>
                        <div className='form_select_drop'>
                            <SelectPicker
                            data={data}
                            searchable={false}
                            style={{ width: '100%' }}
                            placeholder="Select Country"
                            />
                        </div>
                    </div>

                    <div className='col-sm-6 col-lg-2'>
                        <label className='label_form'>Currency<span className='star_red'>*</span></label>
                        <input type='text' className='form-control' placeholder='Currency' />
                    </div>

                    <div className='col-sm-6 col-lg-2'>
                        <label className='label_form'>IPO Date<span className='star_red'>*</span></label>
                        <div className='form_datepicker'>
                            <DatePicker oneTap style={{ width: "100%" }} placement="auto" placeholder="DD/MM/YYYY" caretAs={CustomCaretIcon} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='form_details_group mt-3'>
                <div className='custom_row'>
                    <div className='custom_col5'>
                        <label className='label_form'>Market Capitalization<span className='star_red'>*</span></label>
                        <input type='number' className='form-control' placeholder='Market Capitalization' />
                    </div>

                    <div className='custom_col5'>
                        <label className='label_form'>No. of Outstanding Shares<span className='star_red'>*</span></label>
                        <input type="number" className="form-control" placeholder="Outstanding Number" />
                    </div>

                    <div className='custom_col5'>
                        <label className='label_form'>ISIN No.<span className='star_red'>*</span></label>
                        <input type='number' className='form-control' placeholder='ISIN No.' />
                    </div>

                    <div className='custom_col5'>
                        <label className='label_form'>CUSIP No.</label>
                        <input type='number' className='form-control' placeholder='CUSIP No.' />
                    </div>

                    <div className='custom_col5'>
                        <label className='label_form'>Sedol No.</label>
                        <input type='number' className='form-control' placeholder='Sedol No.' />
                    </div>
                </div>
            </div>
        </div>

        <div className='mt-4'>
            <h2>Industry Details</h2>

            <div className='form_details_group mt-3'>
                <div className='custom_row'>
                    <div className='custom_col5'>
                        <label className='label_form'>Finnhub Industry Classification<span className='star_red'>*</span></label>
                        <input type='number' className='form-control' placeholder='Finnhub Industry' />
                    </div>

                    <div className='custom_col5'>
                        <label className='label_form'>Sector<span className='star_red'>*</span></label>
                        <input type="number" className="form-control" placeholder="Sector" />
                    </div>

                    <div className='custom_col5'>
                        <label className='label_form'>Industry Group</label>
                        <input type='number' className='form-control' placeholder='Industry Group' />
                    </div>

                    <div className='custom_col5'>
                        <label className='label_form'>Industry</label>
                        <input type='number' className='form-control' placeholder='Industry' />
                    </div>

                    <div className='custom_col5'>
                        <label className='label_form'>Sub-Industry</label>
                        <input type='number' className='form-control' placeholder='Sub-Industry' />
                    </div>
                </div>
            </div>

            <div className='form_details_group mt-3'>
                <div className='row'>
                    <div className='col-sm-6 col-lg-3'>
                        <label className='label_form'>NAICS Industry</label>
                        <input type='text' className='form-control' placeholder='NAICS Industry' />
                    </div>

                    <div className='col-sm-6 col-lg-3'>
                        <label className='label_form'>NAICS National Industry</label>
                        <input type='text' className='form-control' placeholder='NAICS national industry' />
                    </div>

                    <div className='col-sm-6 col-lg-3'>
                        <label className='label_form'>NAICS Sector</label>
                        <input type='text' className='form-control' placeholder='NAICS Sector' />
                    </div>

                    <div className='col-sm-6 col-lg-3'>
                        <label className='label_form'>NAICS Sub-Sector</label>
                        <input type='text' className='form-control' placeholder='NAICS Sub-Sector' />
                    </div>
                </div>
            </div>

            
        </div>
      </div>
    </div>

    <div className='add_new_btn_group'>
        <button className='btn_cancel_form'>Cancel</button>
        <button className='btn_save_form'>Save</button>
    </div>

    </>
  );
}

export default AddNewCompanyProfile;
