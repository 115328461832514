import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker } from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';
import IconExcel from "../images/icon-excel.svg";
import IconEFTToggle from "../images/btn_eft_toggle.svg";
import 'aos/dist/aos.css';
import AOS from 'aos';

function ETFHolding() {
    const [tabName, setTabName] = useState('ETF Holding');

    useEffect(() => {
      document.title = tabName;
    }, [tabName]);

const data = ['India', 'United State', 'Australia', 'Canada', 'Israel', 'Germany'].map(
    item => ({ label: item, value: item })
);
const data2 = ['Cash', 'Bond', 'Derivative', 'Stock', 'ETF', 'Cash (Shariah-Compliant)', 'Money Market Fund', 'Proffered Stock (Not Compliant) ', 'Other Items', 'Other'].map(
    item => ({ label: item, value: item })
);

// TOGGLE SHOW HIDE
const [isContentVisible, setIsContentVisible] = useState(false);

const toggleContent = () => {
    setIsContentVisible(prevState => !prevState);
};

useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>CHANGEBRIDGE LONG/SHORT EQUITY ETF</div>
        </div>

        <div className='header_table_filter' style={{width:"auto"}}>
            <div className='searchfield' style={{maxWidth:120}}>
                <button className='btn_search'><i className='icon-search'></i></button>
                <input type='text' className='filter_searchfield' placeholder='Search' />
            </div>

            <div className='dropdown_style filter_by_country'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{width:'100%' }}
                    placeholder="All Asset classes"
                    />
            </div>
            <div className='dropdown_style'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{width:'100%' }}
                    placeholder="All Compliance statuses"
                    />
            </div>
            <a href='#' className="btn_clear_filter">Clear</a>
        </div>
    </div>
    <div className="container-fluid content pb-5">
     <div id="etf_toggle_row" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
        <div className='etf_head_row'>
            <ul>
                <li className='stock_lable'>543375.BO</li>
                <li className='not_halal_tag tag_td' style={{margin:'auto'}}>Not halal</li>
            </ul>

            <div className='etf_right_controls'>
                <div className='input__group'>
                    <div className='input__group_label'>
                        Total Revenue
                    </div>
                    <div className='input__group_value'>
                      Undefined (USD)
                    </div>
                </div>

                <div className='input__group'>
                    <div className='input__group_label'>
                      Calculation Type
                    </div>
                    <div className='input__group_value'>
                       <span className='css_radio'></span><span>Automatic</span>
                    </div>
                </div>
                <button className='btn_export_excel'><img src={IconExcel} />Export to Excel</button>
                <button className='btn_etf_calculate'>Calculate</button>
            </div>
        </div>
        <div className={`etf_content_row_outer ${isContentVisible ? 'open' : ''}`}>
            <div className='etf_content_row'>
                <fieldset className='fieldset_col'>
                    <legend className='legend_title'>Market Info</legend>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Asset
                        </div>
                        <div className='input__group_redis_value'>
                        Asset Allocation
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Market
                        </div>
                        <div className='input__group_redis_value'>
                            US
                        </div>
                    </div>
                </fieldset>

                <fieldset className='fieldset_col'>
                    <legend className='legend_title'>Holdings</legend>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Covered
                        </div>
                        <div className='input__group_redis_value'>
                        172
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Uncovered
                        </div>
                        <div className='input__group_redis_value'>
                        7
                        </div>
                    </div>
                </fieldset>

                <fieldset className='fieldset_col three_rows_fieldset'>
                    <legend className='legend_title'>Compliance (USD)</legend>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Halal
                        </div>
                        <div className='input__group_redis_value'>
                        82.95% <span>(14,644,533.24)</span>
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Not Halal
                        </div>
                        <div className='input__group_redis_value'>
                        9.53% <span>(1,681,738.69)</span>
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Doubtful
                        </div>
                        <div className='input__group_redis_value'>
                        7.52% <span>(1,328,101.48)</span>
                        </div>
                    </div>
                </fieldset>

                <fieldset className='fieldset_col'>
                    <legend className='legend_title'>Interest Bearing</legend>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        SecAndAssets
                        </div>
                        <div className='input__group_redis_value'>
                        27.32% <span>(12,635,747.49)</span>
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        Debt
                        </div>
                        <div className='input__group_redis_value'>
                        51.06% <span>(23,614,663.09)</span>
                        </div>
                    </div>
                </fieldset>

                <fieldset className='fieldset_col'>
                    <legend className='legend_title'>Average Market Cap (USD)</legend>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        36-mo
                        </div>
                        <div className='input__group_redis_value'>
                            27.32% <span>(12,635,747.49)</span>
                        </div>
                    </div>

                    <div className='input__group_redis'>
                        <div className='input__group_redis_label'>
                        24-mo
                        </div>
                        <div className='input__group_redis_value'>
                            27.32% <span>(12,635,747.49)</span>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>

        <button className="btn_eft_toggle" onClick={toggleContent}>
            <img src={IconEFTToggle} />
        </button>
     </div>
      <div className='company_table_list etf_holding_table' data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">
        <div className='table-responsive' style={{paddingBottom:0}}>
          <table className='table'>
              <thead>
                <tr>
                  <th style={{textAlign:'center'}}>Symbol</th>
                  <th>Company</th>
                  <th style={{textAlign:'center'}}>ISIN</th>
                  <th style={{textAlign:'center'}}>Intr</th>
                  <th style={{textAlign:'center'}}>Asset</th>
                  <th style={{textAlign:'center'}}>Weight(%)</th>
                  <th style={{textAlign:'center'}}>Share</th>
                  <th style={{textAlign:'center'}}>Value($)</th>
                  <th style={{textAlign:'center'}}>Compliance</th>
                  <th style={{textAlign:'center'}}>CBA</th>
                  <th style={{textAlign:'center'}}>LUD</th>
                </tr>
              </thead>
              <tbody>
              <tr className="table_tr_odd"> 
                <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow1" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                </td>
                <td data-bs-toggle="collapse" data-bs-target="#TableRow1" className="accordion-toggle collapsed">
                    Mercedes-Benz Group
                </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow1" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow1" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow1" className="accordion-toggle collapsed">22.637264</td>                  
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow1" >
                    <div style={{textAlign:'center'}}>
                    19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow1" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow1" className="accordion-toggle collapsed"><span className='uncover_tag tag_td' style={{margin:'auto'}}>Uncovered</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow1" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow1" className="accordion-toggle collapsed">
                    <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr class="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div class="accordian-body collapse" id="TableRow1">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_even"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow2" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow2" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow2" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow2" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow2" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow2" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow2" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow2" className="accordion-toggle collapsed"><span className='not_halal_tag tag_td' style={{margin:'auto'}}>Not halal</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow2" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow2" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr className="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div className="accordian-body collapse" id="TableRow2">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                
                <tr className="table_tr_odd"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed">
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed"><span className='halal_tag tag_td' style={{margin:'auto'}}>Halal</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow3" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr class="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div class="accordian-body collapse" id="TableRow3">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_even"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow4" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow4" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow4" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow4" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow4" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow4" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow4" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow4" className="accordion-toggle collapsed"><span className='uncover_tag tag_td' style={{margin:'auto'}}>Uncovered</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow4" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow4" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr className="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div className="accordian-body collapse" id="TableRow4">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_odd"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow5" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow5" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow5" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow5" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow5" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow5" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow5" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow5" className="accordion-toggle collapsed"><span className='uncover_tag tag_td' style={{margin:'auto'}}>Uncovered</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow5" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow5" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr class="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div class="accordian-body collapse" id="TableRow5">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_even"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow6" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow6" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow6" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow6" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow6" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow6" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow6" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow6" className="accordion-toggle collapsed"><span className='not_halal_tag tag_td' style={{margin:'auto'}}>Not Halal</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow6" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow6" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr className="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div className="accordian-body collapse" id="TableRow6">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>


                <tr className="table_tr_odd"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow7" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow7" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow7" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow7" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow7" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow7" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow7" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow7" className="accordion-toggle collapsed"><span className='uncover_tag tag_td' style={{margin:'auto'}}>Uncovered</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow7" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow7" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr class="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div class="accordian-body collapse" id="TableRow7">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_even"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow8" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow8" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow8" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow8" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow8" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow8" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow8" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow8" className="accordion-toggle collapsed"><span className='not_halal_tag tag_td' style={{margin:'auto'}}>Not halal</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow8" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow8" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr className="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div className="accordian-body collapse" id="TableRow8">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                
                <tr className="table_tr_odd"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow9" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow9" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow9" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow9" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow9" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow9" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow9" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow9" className="accordion-toggle collapsed"><span className='halal_tag tag_td' style={{margin:'auto'}}>Halal</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow9" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow9" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr class="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div class="accordian-body collapse" id="TableRow9">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_even"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow10" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow10" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow10" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow10" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow10" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow10" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow10" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow10" className="accordion-toggle collapsed"><span className='uncover_tag tag_td' style={{margin:'auto'}}>Uncovered</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow10" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow10" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr className="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div className="accordian-body collapse" id="TableRow10">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_odd"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow11" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow11" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow11" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow11" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow11" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow11" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow11" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow11" className="accordion-toggle collapsed"><span className='uncover_tag tag_td' style={{margin:'auto'}}>Uncovered</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow11" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow11" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr class="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div class="accordian-body collapse" id="TableRow11">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_even"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow12" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow12" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow12" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow12" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow12" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow12" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow12" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow12" className="accordion-toggle collapsed"><span className='not_halal_tag tag_td' style={{margin:'auto'}}>Not Halal</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow12" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow12" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr className="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div className="accordian-body collapse" id="TableRow12">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_even"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow13" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow13" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow13" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow13" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow13" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow13" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow13" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow13" className="accordion-toggle collapsed"><span className='uncover_tag tag_td' style={{margin:'auto'}}>Uncovered</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow13" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow13" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr className="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div className="accordian-body collapse" id="TableRow13">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_odd"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow14" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow14" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow14" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow14" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow14" className="accordion-toggle collapsed">22.637264</td>                  
                    <td>
                    <div style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow14" className="accordion-toggle collapsed">
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow14" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow14" className="accordion-toggle collapsed"><span className='uncover_tag tag_td' style={{margin:'auto'}}>Uncovered</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow14" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow14" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}}>Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr class="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div class="accordian-body collapse" id="TableRow14">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr className="table_tr_even"> 
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed">
                    <span className='stock_lable' style={{margin:'auto'}}>543375.BO</span>
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed">
                        Mercedes-Benz Group
                    </td>
                    
                    <td style={{textAlign:'center', color:'#2659C9'}} data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed">US09789C8872</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed">Var</td>
                    <td style={{textAlign:'center'}}>
                    <div className='dropdown_style table_dropdown2' style={{maxWidth:'fit-content', margin:'auto'}}>
                        <SelectPicker
                            data={data2}
                            searchable={false}
                            style={{width: 200}}
                            placeholder=""
                            />
                    </div>
                    </td>
                    <td style={{textAlign:'center', fontWeight:600}} data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed">22.637264</td>                  
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed">
                    <div style={{textAlign:'center'}}>
                        19,315,391
                    </div>    
                    </td>
                    <td data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed">37,856,023.11</td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed"><span className='not_halal_tag tag_td' style={{margin:'auto'}}>Not Halal</span></td>                  
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed"><span className='cba_pass'>PASS</span></td>
                    <td style={{textAlign:'center'}} data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed">
                        <p style={{color:'#000',}} data-bs-toggle="collapse" data-bs-target="#TableRow15" className="accordion-toggle collapsed">Oct 12, 2023<br />
                        <span style={{color:'#878787',}}>5:09:29 AM</span></p>
                    </td>
                </tr>

                <tr className="hiddenRow">
                    <td colspan="11" style={{padding:0}}>
                        <div className="accordian-body collapse" id="TableRow15">
                            <div className='table_accordion_row'>
                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Company Details</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>Ratio</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Interest
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.78%
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Debt
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    5.27%
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'60%'}}>
                                            <legend className='legend_title'>Market Info</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Total Revenue
                                                </div>
                                                <div className='input__group_redis_value'>
                                                144,847,000,000.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Tariling36moAvrCap
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1,435,723,104,256.00 USD
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Shares Outstanding
                                                </div>
                                                <div className='input__group_redis_value'>
                                                10,383.00
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>Compliance Ratio</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                74.69%
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                1.06%
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                24.25%
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className='table_accordion_col'>
                                    <div className='etf_details_row'>
                                        <h4>Owned By The ETF</h4>
                                        <fieldset className='fieldset_col'>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group__row'>
                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Total
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    941,903.09
                                                    </div>
                                                </div>

                                                <div className='input__group_redis'>
                                                    <div className='input__group_redis_label'>
                                                    Per Share
                                                    </div>
                                                    <div className='input__group_redis_value'>
                                                    13.95
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='etf_fieldset_row'>
                                        <fieldset className='fieldset_col' style={{width:'60%'}}>
                                            <legend className='legend_title'>ETF Interest Bearing</legend>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                SecAndAssets
                                                </div>
                                                <div className='input__group_redis_value'>
                                                941,903.09
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Debt
                                                </div>
                                                <div className='input__group_redis_value'>
                                                13.95
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset className='fieldset_col three_rows_fieldset' style={{width:'37.5%'}}>
                                            <legend className='legend_title'>ETF Revenue</legend>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                703526.76
                                                </div>
                                            </div>

                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Not Halal
                                                </div>
                                                <div className='input__group_redis_value'>
                                                9,942.70
                                                </div>
                                            </div>
                                            <div className='input__group_redis'>
                                                <div className='input__group_redis_label'>
                                                Doubtful
                                                </div>
                                                <div className='input__group_redis_value'>
                                                228,433.63
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                
              </tbody> 
          </table>
        </div>
      </div>
    </div>
    </>
  );
}

export default ETFHolding;
