import React, { useState, useEffect, useCallback } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Popover, Whisper, Button, Message } from 'rsuite';
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
import debounce from 'lodash.debounce';

function StockRequests() {
  const [tabName, setTabName] = useState('Stock Requests');
  const [isLoader, setIsLoader] = useState(false);

  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const clientApiEndpoint = process.env.REACT_APP_CLIENT_API_ENDPOINT;
  const userServiceKey = process.env.REACT_APP_USER_SERVICE_KEY;

  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info

  const [searchInput, setSearchInput] = useState("");
  
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);

  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState(true); // State to manage loading state

  const data_Status = [
    { label: "Pending", value: "pending" },
    { label: "Completed", value: "completed" },
  ];

  const [status, setStatus] = useState("");

  // Fetch stock requests from API
  const fetchStockRequests = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${clientApiEndpoint}/api/analyst/updation-requests?sort_by=latest_request_at&sort_order=desc&per_page=20&page=${currentPage.toString()}${status !== null ? `&status=${status}&q=${searchInput}` : ''}`, {
        headers: {
          'X-USER-SERVICE-KEY': `${userServiceKey}`
        }
      });
      const data = await response.json();
      if (data.status) {
        setReportData(data.data.data);
        setReportCount(data.data.total);
      } else {
        // setError('Failed to fetch data');
      }
    } catch (err) {
      //  setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
      fetchStockRequests();
    }, 500),
    [searchInput] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [searchInput]);

  useEffect(() => {
    

    fetchStockRequests();
  }, [currentPage, status]);




  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption); // Update selectedPeriod state with the selected option
  };

  const totalPages = Math.ceil(reportCount / 20);
  const endPage = Math.min(startPage + 4, totalPages);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (page >= startPage + 5) {
      setStartPage(page - 4);
    } else if (page < startPage) {
      setStartPage(page);
    }
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
        </li>
      );
    }

    return (
      <nav aria-label="..." id='pagination_row'>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
              <i className='fa fa-angle-double-left'></i>
            </a>
          </li>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
              <i className='icon-prev'></i>
            </a>
          </li>
          {pages}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
              <i className='icon-next'></i>
            </a>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
              <i className='fa fa-angle-double-right'></i>
            </a>
          </li>
        </ul>
      </nav>
    );
  };


  const options = [
    { value: 'India', label: 'India' },
    { value: 'United States', label: 'United States' },
    { value: 'Australia', label: 'Australia' }
  ];

  const colourStyles = {
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "#F8F8F8" : "",
      color: "#333333",
      borderBottom: 1,
      borderStyle: "solid",
      borderColor: "#F2F2F2",
      fontSize: 14,
      fontWeight: 500,
    })
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = e => {
    setSelectedOption(e);
  };

  const [copied, setCopied] = useState(false);

  const copyText = text => {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    setStatus("");
    setSearchInput("");

  };


// COMPLETED POPOVER
// const data = ['updated', 'already_uptodate', 'not_applicable'].map(
//   item => ({ label: item, value: item })
// );

// const [data, setData] = useState([
//   { key: 'key1', value: 'updated' },
//   { key: 'key2', value: 'already_uptodate' },
//   { key: 'key3', value: 'not_applicable' }
// ]);

const data = [
  { label: "Updated", value: "updated" },
  { label: "Already Update", value: "already_uptodate" },
  { label: "Not Applicable", value: "not_applicable" },
];



// const keys = ['Update', 'Already Update', 'Not Applicable'];
// const values = ['updated', 'already_uptodate', 'not_applicable'];

// // Example of how to access or combine them later
// const data = keys.map((key, index) => ({
//   key: key,
//   value: values[index]
// }));

// State to track the visibility of the popover
const [openReportPopover, setOpenReportPopover] = useState(false);
// State to track the selected value from SelectPicker
const [selectedStatus, setSelectedStatus] = useState(null);
// State to track the selected radio option
const [selectedRadio, setSelectedRadio] = useState(null);

// Handle SelectPicker change
const handleSelect = (value) => {

  // Reset radio selection when the country changes
  //setSelectedRadio(null);
  setSelectedStatus(value);
  if(value == 'not_applicable')
  {
    setSelectedRadio(true);
  }
  
};

// Handle Radio button change
const handleRadioChange = (value) => {
  setSelectedRadio(value);
};

const handlePopoverOpen = (id) => {
  setOpenReportPopover(openReportPopover == id ? null : id);
  setSelectedStatus(null);
};

  const [email, setEmail] = useState('');
  const [id, setId] = useState('');


const handleRequest = async (request) => {
  //const result = await getStatusReport(request.ticker);
  setIsLoader(true);
  const result = await getStatusReport(request.ticker);
    if (result) {
      setIsLoader(true);
      setEmail(result.email);
      setId(result.id);

      //console.log('RESULT : '+ result.email + ' : ID :'+ result.id + ' : selectedStatus : ' + selectedStatus + ' : Radio :'+ selectedRadio);

      try {
        const response = await fetch(`${clientApiEndpoint}/api/analyst/updation-requests/update-status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-USER-SERVICE-KEY': `${userServiceKey}`
          },
          body: JSON.stringify({
            request_id: request.id,
            analyst_email: result.email,
            status: selectedStatus,
            status_reason: selectedRadio === null ? '' : selectedRadio
          })
        });

        console.log(JSON.stringify({
          request_id: request.id,
          analyst_email: result.email,
          status: selectedStatus,
          status_reason: selectedRadio === null ? '' : selectedRadio
        }));

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
          setErrorMessage(`HTTP error! status: ${response.status}`);
      setMessageType('error');
          setIsLoader(false);
        }
    
        const data = await response.json();
        //console.log('Success:', data);
        setIsLoader(false);
        if(data.status == false)
        {
          console.log('FAIL');
          setOpenReportPopover(null)
          setErrorMessage(data.message || 'An error occurred');
          setMessageType('error');
        }
        else{
          setOpenReportPopover(null);
          setErrorMessage('Request updated successfully!');
          setMessageType('success');
          fetchStockRequests();
        }

  
      } catch (error) {
        setErrorMessage(`Error: `+error);
        setMessageType('error');
        console.error('Error:', error);
        setIsLoader(false);
      }

    }
};

// Function to call the get_status_report API
const getStatusReport = async (ticker) => {
  const payload = {
    data: {
      user_id: "1",
      limit: "20",
      page: "1",
      sort_order: "desc",
      q: ticker,  // Pass the ticker as a query parameter
      country_code: "",
      filter_Date: "",
      status: 3
    }
  };

  try {
    const response = await fetch(`${apiEndPoint}/get_status_report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
    const data = await response.json();

    if (data.data && data.data.data.length > 0) {
      const result = data.data.data[0];
      return {
        email: result.email,
        id: result.id
      };
    } else {
      setIsLoader(false);
      console.error("Report not published yet, so you can't update the status");
      setErrorMessage('Report not published yet, so you cant update the status');
      setMessageType('error');
      return null;
    }
  } catch (error) {
    setIsLoader(false);
    console.error('Error fetching data:', error);
    return null;
  }
};


  // if (assignedId) {
  //   console.log("Selected Junior Id:", assignedId);

  //   //0 : indicated dont send the data on screening page
  //   handleProcessReport(assignedId,company_symbol,date,quarter,year,0);
  //   setSelectedUser(null);
  //   setOpenReportPopover(null);
  // } else {
  //   console.log("No user selected"+ assignedId);
  // }

  const hideMessage = () => {
    setMessageType(null);
  };

  // Error Message

  const renderMessage = () => {
    if (messageType) {
      setTimeout(hideMessage, 5000);
    };

    switch (messageType) {
      case 'success':
        return (
          <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_success'>
                <div className='icon_error_msg_inner Icon_success_inner'>
                  <img src={IconCheckError} alt="Success Icon" />
                  <div className="progress-circle progress_circle_success"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_success_text'>
                <h5>Success</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
      case 'error':
        return (
          <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_error'>
                <div className='icon_error_msg_inner Icon_error_inner'>
                  <img src={IconError} alt="Error Icon" />
                  <div className="progress-circle progress_circle_error"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_error_text'>
                <h5>Error</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
        case 'info':
        return (
          <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_info'>
                <div className='icon_error_msg_inner Icon_info_inner'>
                  <img src={IconInfo} alt="Info Icon" />
                  <div className="progress-circle progress_circle_info"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_info_text'>
                <h5>Info</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
        default:
          return null;
    }
  };

  const handleSearch = (event) => {
    console.log(event.target.value);
  
    setSearchInput(event.target.value);
    setCurrentPage(1); // Reset to the first page for new search
  };


  return (
    <>
      <Sidebar />
      <div id='header'>
      {renderMessage()}
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Stock Requests</div>
        </div>

        <div className='header_table_filter'>
        <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search' autocomplete="off"  onChange={handleSearch}   value={searchInput}/>
          </div>


          <div className=' dropdown_select'>
            <SelectPicker
              data={data_Status}
              searchable={false}
              placeholder="Status"
              style={{ width: 130 }}
              onChange={handleStatusChange} // Attach onChange event handler
              value={status} // Set the value prop to control the selected value
            />
          </div>

          <a href="javascript:void(0)" className="btn_clear_filter" style={{ textDecoration: "none" }} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
      </div>
      <div className="container-fluid content pb-5">
        <div className='company_table_list'>
          {isLoading && (<Placeholder.Grid rows={20} columns={9} active style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 12 }} />)}

          {/* Table */}
          {!isLoading && (
            <>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Ticker</th>
                      <th>Request Count</th>
                      <th>Request Date</th>
                      <th>Merlin Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.length > 0 ? (
                      reportData.map((request, index) => (
                        <tr key={index}>
                          <td><span className='company_lable2'>{request.ticker}</span></td>
                          <td>{request.request_count}</td>
                          <td>{new Date(request.latest_request_at).toLocaleDateString()}</td>
                          <td><span className={`tag_td2 ${request.status === "completed" ? 'covered_tag' : request.status === "pending" ? 'not_covered_tag' : ''}`}


                          >{request.status.toUpperCase()}</span></td>

                          <td>
                          <Whisper
                            placement="auto"
                            trigger="click"
                            open={openReportPopover == request.ticker}
                            onClose={() => setOpenReportPopover(null)}
                            speaker={
                              <Popover 
                                className=""
                                container={() => document.body}
                                style={{width:420, marginTop: -4, marginLeft:-12, }} // Optional: Ensure consistent width
                              >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <div className="tooltip_form_inner">
                                  
                                    <label>Status For {request.ticker}</label>
                                    <div>
                                      <SelectPicker
                                        data={data}
                                        searchable={false}
                                        placeholder="Select Status"
                                        style={{ width: '100%' }}
                                        onChange={handleSelect}
                                      />
                                    </div>
                                    {selectedStatus === 'not_applicable' && (
                                      <ul className='radio_check_list mt-3'>
                                      <li>
                                          <label for="opt1" class="radio">
                                            <input type="radio" name="rdo" id="opt1" class="hidden" onChange={() => handleRadioChange('acquired')}/>
                                            <span class="label"></span>acquired
                                          </label>
                                        </li>
                                        <li>
                                          <label for="opt2" class="radio">
                                            <input type="radio" name="rdo" id="opt2" class="hidden" onChange={() => handleRadioChange('merged')}/>
                                            <span class="label"></span>merged
                                          </label>
                                        </li>

                                        <li>
                                          <label for="opt3" class="radio">
                                            <input type="radio" name="rdo" id="opt3" class="hidden" onChange={() => handleRadioChange('ceased')}/>
                                            <span class="label"></span>ceased
                                          </label>
                                        </li>
                                        
                                        <li>
                                          <label for="opt4" class="radio">
                                            <input type="radio" name="rdo" id="opt4" class="hidden" onChange={() => handleRadioChange('bankrupt')}/>
                                            <span class="label"></span>bankrupt 
                                          </label>
                                        </li>

                                        <li>
                                          <label for="opt5" class="radio">
                                            <input type="radio" name="rdo" id="opt5" class="hidden" onChange={() => handleRadioChange('delisted')}/>
                                            <span class="label"></span>delisted
                                          </label>
                                        </li>

                                        <li>
                                          <label for="opt6" class="radio">
                                            <input type="radio" name="rdo" id="opt6" class="hidden" onChange={() => handleRadioChange('suspended')}/>
                                            <span class="label"></span>suspended
                                          </label>
                                        </li>

                                        <li>
                                          <label for="opt7" class="radio">
                                            <input type="radio" name="rdo" id="opt7" class="hidden" onChange={() => handleRadioChange('private_company')}/>
                                            <span class="label"></span>private_company
                                          </label>
                                        </li>

                                        <li>
                                          <label for="opt8" class="radio">
                                            <input type="radio" name="rdo" id="opt8" class="hidden" onChange={() => handleRadioChange('closed_end_trust')}/>
                                            <span class="label"></span>closed_end_trust
                                          </label>
                                        </li>

                                      </ul>
                                    )}

                                    <div className='btn__group mt-5'>
                                      <a href="#" className="btn__cancel" onClick={(e) => { e.preventDefault(); setOpenReportPopover(null) }}>Cancel</a>
                                      <Button className="btn__assign" onClick={() => handleRequest(request) } loading={isLoader}> Submit </Button>
                                    </div>

                                  </div>
                                </div>
                              </Popover>
                            }
                          >
                              <button className="btn_completed" onClick={() => handlePopoverOpen(request.ticker)}>
                                Change Status
                              </button>
                          </Whisper>
                            
                          </td>
                        </tr>

                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {totalPages > 1 && renderPagination()}


            </>
          )}
        </div>
      </div>
    </>
  );
}

export default StockRequests;
