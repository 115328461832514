import React, { useState, useEffect, useCallback } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Message, Button } from 'rsuite';
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
import debounce from 'lodash.debounce';

function StockCoverage() {
  const [tabName, setTabName] = useState('Stock Coverage');


  useEffect(() => {
    document.title = tabName;
  }, [tabName]);
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const clientApiEndpoint = process.env.REACT_APP_CLIENT_API_ENDPOINT;
  const userServiceKey = process.env.REACT_APP_USER_SERVICE_KEY;

  const [loadingRowId, setLoadingRowId] = useState(null);

  

  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState(true); // State to manage loading state

  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info
  const [searchInput, setSearchInput] = useState("");

  const data_Status = [
    { label: "Pending", value: "pending" },
    { label: "Completed", value: "completed" },
  ];

  const [status, setStatus] = useState("");
  
   // Fetch stock requests from API
   const fetchStockRequests = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${clientApiEndpoint}/api/analyst/coverage-requests?q=&sort_by=latest_request_at&sort_order=desc&per_page=20&page=${currentPage.toString()}${status !== null ? `&status=${status}&q=${searchInput}` : ''}`, {
        headers: {
          'X-USER-SERVICE-KEY': `${userServiceKey}`
        }
      });
      const data = await response.json();
      console.log(data);
      if (data.status) {
        setReportData(data.data.data);
        setReportCount(data.data.total);
      } else {
       // setError('Failed to fetch data');
      }
    } catch (err) {
    //  setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
      fetchStockRequests();
    }, 500),
    [searchInput] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [searchInput]);

  useEffect(() => {
    fetchStockRequests();
  }, [currentPage,status]);


  

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption); // Update selectedPeriod state with the selected option
  };

  const totalPages = Math.ceil(reportCount / 20);
  const endPage = Math.min(startPage + 4, totalPages);
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (page >= startPage + 5) {
      setStartPage(page - 4);
    } else if (page < startPage) {
      setStartPage(page);
    }
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
        </li>
      );
    }
  
    return (
      <nav aria-label="..." id='pagination_row'>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
              <i className='fa fa-angle-double-left'></i>
            </a>
          </li>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
              <i className='icon-prev'></i>
            </a>
          </li>
          {pages}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
              <i className='icon-next'></i>
            </a>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
              <i className='fa fa-angle-double-right'></i>
            </a>
          </li>
        </ul>
      </nav>
    );
  };


  const options = [
    { value: 'India', label: 'India' },
    { value: 'United States', label: 'United States' },
    { value: 'Australia', label: 'Australia' }
  ];

  const colourStyles = {
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "#F8F8F8" : "",
      color: "#333333",
      borderBottom: 1,
      borderStyle: "solid",
      borderColor: "#F2F2F2",
      fontSize: 14,
      fontWeight: 500,
    })
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = e => {
    setSelectedOption(e);
  };

  const [copied, setCopied] = useState(false);

  const copyText = text => {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  
  const handleClearFilters = () => {
    setCurrentPage(1);
    setStatus("");
    setSearchInput("");

  };


  const [email, setEmail] = useState('');
  const [id, setId] = useState('');


const handleRequest = async (request) => {
  //const result = await getStatusReport(request.ticker);
  setLoadingRowId(request.ticker); // Set loading row id
  const result = await getStatusReport(request.ticker);
    if (result) {
      setEmail(result.email);
      setId(result.id);

      //console.log('RESULT : '+ result.email + ' : ID :'+ result.id + ' : selectedStatus : ' + selectedStatus + ' : Radio :'+ selectedRadio);

      try {
        const response = await fetch(`${clientApiEndpoint}/api/analyst/coverage-requests/update-status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-USER-SERVICE-KEY': `${userServiceKey}`
          },
          body: JSON.stringify({
            ticker: request.ticker,
            analyst_email: result.email
          })
        });

        console.log(JSON.stringify({
          ticker: request.ticker,
          analyst_email: result.email,
        }));

        if (!response.ok) {
          setLoadingRowId(false);
          //throw new Error(`HTTP error! status: ${response.status}`);
          setErrorMessage(`HTTP error! status: ${response.status}`);
          setMessageType('error');
          //setLoadingRowId(false);
        }
    
        const data = await response.json();
        //console.log('Success:', data);
       // setIsLoader(false);
        if(data.status == false)
        {
          console.log('FAIL');
          //setOpenReportPopover(null)
          setLoadingRowId(false);
          setErrorMessage(data.message || 'An error occurred');
          setMessageType('error');
        }
        else{
         // setOpenReportPopover(null);
         setLoadingRowId(false);
          setErrorMessage('Success');
          setMessageType('success');
          fetchStockRequests();
        }

  
      } catch (error) {
        setLoadingRowId(false);
        setErrorMessage(`Error: `+error);
        setMessageType('error');
        console.error('Error:', error);
      }

    }
};

// Function to call the get_status_report API
const getStatusReport = async (ticker) => {
  const payload = {
    data: {
      user_id: "1",
      limit: "20",
      page: "1",
      sort_order: "desc",
      q: ticker,  // Pass the ticker as a query parameter
      country_code: "",
      filter_Date: "",
      status: 3
    }
  };

  try {
    const response = await fetch(`${apiEndPoint}/get_status_report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
    const data = await response.json();

    if (data.data && data.data.data.length > 0) {
      const result = data.data.data[0];
      return {
        email: result.email,
        id: result.id
      };
    } else {
      setLoadingRowId(false);
      console.error("Report not published yet, so you can't mark it as completed");
      setErrorMessage('Report not published yet, so you cant mark it as completed');
      setMessageType('error');
      return null;
    }
  } catch (error) {
    setLoadingRowId(false);
    console.error('Error fetching data:', error);
    return null;
  }
};

const hideMessage = () => {
  setMessageType(null);
};

const renderMessage = () => {
  if (messageType) {
    setTimeout(hideMessage, 5000);
  };

  switch (messageType) {
    case 'success':
      return (
        <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_success'>
              <div className='icon_error_msg_inner Icon_success_inner'>
                <img src={IconCheckError} alt="Success Icon" />
                <div className="progress-circle progress_circle_success"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_success_text'>
              <h5>Success</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
    case 'error':
      return (
        <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_error'>
              <div className='icon_error_msg_inner Icon_error_inner'>
                <img src={IconError} alt="Error Icon" />
                <div className="progress-circle progress_circle_error"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_error_text'>
              <h5>Error</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      case 'info':
      return (
        <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_info'>
              <div className='icon_error_msg_inner Icon_info_inner'>
                <img src={IconInfo} alt="Info Icon" />
                <div className="progress-circle progress_circle_info"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_info_text'>
              <h5>Info</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      default:
        return null;
  }
};

const handleSearch = (event) => {
  console.log(event.target.value);

  setSearchInput(event.target.value);
  setCurrentPage(1); // Reset to the first page for new search
};



  return (
    <>
      <Sidebar />
      <div id='header'>
      {renderMessage()}
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Stock Coverage</div>
        </div>

        <div className='header_table_filter'>
        <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search' autocomplete="off"  onChange={handleSearch}   value={searchInput}/>
          </div>

          <div className='dropdown_select'>
            <SelectPicker 
                      data={data_Status}
                      searchable={false}
                      placeholder="Status"
                      style={{ width: 130 }}
                      onChange={handleStatusChange} // Attach onChange event handler
                      value={status} // Set the value prop to control the selected value
                    />
            </div>

            <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
      </div>
      <div className="container-fluid content pb-5">
      <div className='company_table_list'>
        {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Ticker</th>
                  <th>Request Count</th>
                  <th>Request Date</th>
                  <th>Merlin Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {reportData.length > 0 ? (
  reportData.map((request, index) => (
    <tr key={index}>
    <td><span className='company_lable2'>{request.ticker}</span></td>
    <td>{request.request_count}</td>
    <td>{new Date(request.latest_request_at).toLocaleDateString()}</td>
    <td><span className={`tag_td2 ${request.status === "completed" ? 'covered_tag' : request.status === "pending" ? 'not_covered_tag' : '' }`}
    

    >{request.status.toUpperCase()}</span></td>
    
    <td>
      <Button className="btn_completed" onClick={() => handleRequest(request)} loading={loadingRowId === request.ticker}>
      Change Status
      </Button>
    </td>
  </tr>

                ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

          {totalPages > 1 && renderPagination()}


        </>    
)}
        </div>
      </div>
    </>
  );
}

export default StockCoverage;
