import React, { useState, useEffect, useCallback } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker, DatePicker, Dropdown } from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import 'rsuite/dist/rsuite.min.css';
import debounce from 'lodash.debounce';


function ETFHoldingsBasket() {

  const [tabName, setTabName] = useState('ETF Holdings Basket');
    useEffect(() => {
      document.title = tabName;
    }, [tabName]);

  const data = ['Cash', 'Bond', 'Derivative', 'Stock', 'ETF', 'Cash (Shariah-Compliant)', 'Money Market Fund', 'Proffered Stock (Not Compliant) ', 'Other Items', 'Other'].map(
    item => ({ label: item, value: item })
);

const [checkAll, setCheckAll] = useState(false);
    const [checkboxes, setCheckboxes] = useState([
        { id: 1, checked: false },
        { id: 2, checked: false },
        { id: 3, checked: false }
    ]);
    const [activeClass, setActiveClass] = useState('');

    const handleCheckAll = () => {
        const newState = !checkAll;
        setCheckAll(newState);
        setCheckboxes(checkboxes.map(checkbox => ({
            ...checkbox,
            checked: newState
        })));
        setActiveClass(newState ? 'active' : '');
    };

    const handleCheckboxChange = (id) => {
        const newCheckboxes = checkboxes.map(checkbox => {
            if (checkbox.id === id) {
                return { ...checkbox, checked: !checkbox.checked };
            }
            return checkbox;
        });
        setCheckboxes(newCheckboxes);
        setCheckAll(newCheckboxes.every(checkbox => checkbox.checked));
        setActiveClass(newCheckboxes.some(checkbox => checkbox.checked) ? 'active' : '');
    };

    useEffect(() => {
      AOS.init();
    }, []);

    const [isLoading, setLoading] = useState(true); // State to manage loading state
  const apiEndPoint = process.env.REACT_APP_ETF_API_ENDPOINT;
  const frontEndLink = process.env.REACT_APP_MUSAFFA_LINK;
  const roleName = localStorage.getItem("novaRolename");
  const merlinJuniorAnalyst = process.env.REACT_APP_MERLIN_JUNIOR_ANALYST;

  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [selectedQuarter, setSelectedQuarter] = useState("");

  const userid = localStorage.getItem("novauserid");
  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;


  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info


    const fetchGlobalReport = async () => {
      setLoading(true);
  
          try {
        const apiUrl = `${apiEndPoint}/etf_holdings_basket`;
        const requestData = {
          data: {
            user_id: userid,
            limit: "20",
            page: currentPage.toString(),
            q:searchInput,
          }
        };
        console.log(JSON.stringify(requestData));
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        });
  
        const jsonData = await response.json();
        const { status, message, count, data } = jsonData.data;
        if (status == "success") {
          setLoading(false);
          setReportCount(count);
          console.log('RES :'+ searchInput + ' : '+ JSON.stringify(data));
          setReportData(data);
        } else {
          console.error("Error: ", message);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
  
    const debouncedFetchGlobalReport = useCallback(
      debounce(() => {
        fetchGlobalReport();
      }, 500),
      [searchInput] // Only debounce on search input changes
    );
  
    useEffect(() => {
      debouncedFetchGlobalReport();
      return () => {
        debouncedFetchGlobalReport.cancel();
      };
    }, [searchInput]);
  
    useEffect(() => {
      fetchGlobalReport();
    }, [currentPage, countryCode, complianceId, statusId]); // Regular effect for other dependencies

    
    const handlePageChange = (page) => {
      setCurrentPage(page);
      if (page >= startPage + 5) {
        setStartPage(page - 4);
      } else if (page < startPage) {
        setStartPage(page);
      }
    };
  
    const totalPages = Math.ceil(reportCount / 20);
    const endPage = Math.min(startPage + 4, totalPages);

    const handleSearch = (event) => {
      console.log(event.target.value);
    
      setSearchInput(event.target.value);
      setCurrentPage(1); // Reset to the first page for new search
    };


    const renderPagination = () => {
      const pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
          </li>
        );
      }
    
      return (
        <nav aria-label="..." id='pagination_row'>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
                <i className='fa fa-angle-double-left'></i>
              </a>
            </li>
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
                <i className='icon-prev'></i>
              </a>
            </li>
            {pages}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
                <i className='icon-next'></i>
              </a>
            </li>
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
                <i className='fa fa-angle-double-right'></i>
              </a>
            </li>
          </ul>
        </nav>
      );
    };

    const handleClearFilters = () => {
      setCurrentPage(1);
      setCountryCode("");
      setSearchInput("");
      setComplianceId("");
      setStatusId("");
    };





  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>ETF HOLDINGS BASKET</div>
        </div>

        <div className='header_table_filter' style={{width:'auto'}}>
          <Dropdown className='multiselect_drop' title={<i className='icon-minus'></i>}>
            <Dropdown.Item>Cash</Dropdown.Item>
            <Dropdown.Item>Bond</Dropdown.Item>
            <Dropdown.Item>Derivative</Dropdown.Item>
            <Dropdown.Item>Stock</Dropdown.Item>
            <Dropdown.Item>ETF</Dropdown.Item>
            <Dropdown.Item>Cash (Shariah-Compliant)</Dropdown.Item>
            <Dropdown.Item>Money Market Fund</Dropdown.Item>
            <Dropdown.Item>Proffered Stock (Not Compliant) </Dropdown.Item>
            <Dropdown.Item>Other Items</Dropdown.Item>
            <Dropdown.Item>Other</Dropdown.Item>
          </Dropdown>

          <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search' autocomplete="off"  onChange={handleSearch}   value={searchInput}/>
          </div>

            <div className='dropdown_col_filter dropdown__select'>
                 <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ }}
                    placeholder="All Asset classes"
                    />
            </div>            

            <a href='#' className="btn_clear_filter">Clear Filters</a>
        </div>
    </div>
    <div className="container-fluid content pb-5">
      <div className='company_table_list' data-aos="fade-up" data-aos-duration="800">
        <div className='table-responsive'>
          <table className='table'>
              <thead>
                <tr>
                  <th style={{width:50}}>
                  <div class="form-check">
                    <input class="form-check-input" 
                    id="CheckAll"
                    type="checkbox"
                    checked={checkAll}
                    onChange={handleCheckAll}
                     />
                    <label class="form-check-label" htmlFor="CheckAll">
                      All
                    </label>
                  </div>
                  </th>
                  <th style={{textAlign:'center'}}>ISIN</th>
                  <th style={{textAlign:'center'}}>Symbol</th>
                  <th style={{textAlign:'center'}}>Name</th>
                  <th style={{textAlign:'center'}}>Key</th>
                  <th style={{textAlign:'center'}}>Asset Class</th>
                  <th style={{textAlign:'center'}}>Interest</th>
                </tr>
              </thead>
              
              <tbody>

              {reportData.length > 0 ? (
  reportData.map((company, index) => (

                <tr className={`table_row_active ${activeClass}`}>
                  <td style={{width:50}}>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" />
                    <label class="form-check-label" htmlFor="flexCheckChecked">
                      &nbsp;
                    </label>
                  </div>
                  </td>
                  <td style={{textAlign:'center'}}>US91282CFL00</td>
                  <td style={{textAlign:'center'}}>UKT 0.125 01.30.26</td>
                  <td style={{textAlign:'center'}}>ILS - SPENDABLE COMMITTED CASH</td>
                  <td style={{textAlign:'center'}}>
                    ILS - Spendable Committed Cash
                  </td>
                  <td style={{textAlign:'center'}}>
                  <div className='table_dropdown'>
                      <SelectPicker
                          data={data}
                          searchable={false}
                          style={{ }}
                          placeholder=""
                          />
                  </div>
                  </td>                  
                  <td style={{textAlign:'center'}}>var</td>
                </tr>
               ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
              </tbody> 
              
          </table>
        </div>

        {totalPages > 1 && renderPagination()}

      </div>
    </div>
    </>
  );
}

export default ETFHoldingsBasket;
