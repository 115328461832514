import React, { useState, useEffect, useCallback } from "react";
import Logo from "../images/logo.svg";
import { SelectPicker, Placeholder } from "rsuite";
import Sidebar from '../component/Sidebar';
import "rsuite/SelectPicker/styles/index.css";
import { ReactComponent as IconStarOutline } from "../images/star-outline.svg";
import { ReactComponent as IconStar } from "../images/IconStar.svg";
import IconNoData from "../images/IconNoData.svg";
import debounce from 'lodash.debounce';

const Ranking = () => {
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [tabName, setTabName] = useState('Scoring & Ranking');
  const [isLoading, setLoading] = useState(true); // State to manage loading state
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);

  const userid = localStorage.getItem("novauserid");


  const [countryData, setCountryData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [ranking, setRanking] = useState("");
  const [standard, setStandard] = useState("");
  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [startPage, setStartPage] = useState(1);


  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState(""); // New state for search query
  //const [startPageProgress, setStartPageProgress] = useState(1);
  
  


    const fetchCountryList = async () => {
      const url = `${apiEndPoint}/get_country_list`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        console.log(JSON.stringify(payload));
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response
      console.log(responseData.data);
        const formattedCountryData = data.data.map((country) => ({
          label: country.country_name,
          value: country.country_code,
        }));


        setCountryData(formattedCountryData);
  
      } catch (error) {
        console.log('get_country_list API: '+ error.message);
      }
    };
  

  const fetchStandardData = () => {
    fetch(`${apiEndPoint}/get_standard_master`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          user_id: userid,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const formattedStandardData = data.data.data.map((standard) => ({
          label: standard.name,
          value: standard.id,
        }));
        setStandardData(formattedStandardData);
      })
      .catch((error) => {
        console.log('get_standard_master API :' + error.message);
      });
  };
  
  const fetchTableData = () => {

    setLoading(true);

    const payload = {
      data: {
        user_id: userid,
        limit: "20",
        page: currentPage.toString(),
        q: query,
        country: countryCode,
        standard: standard,
      },
    };

    console.log(JSON.stringify(payload));

    fetch(`${apiEndPoint}/get_ranking_report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setLoading(false);
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.data.status === "success") {
          setLoading(false);
          setReportCount(data.data.count); // Accessing the count and converting it to an integer
          setReportData(data.data.data); // Accessing the nested data array
        } else {
          setLoading(false);
          throw new Error('Data fetch was not successful');
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  

  useEffect(() => {
    fetchCountryList();
    fetchStandardData();
  }, []);

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
      fetchTableData();
    }, 500),
    [query] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [query]);

  useEffect(() => {
    fetchTableData();
  }, [currentPage, countryCode, standard]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (page >= startPage + 5) {
      setStartPage(page - 4);
    } else if (page < startPage) {
      setStartPage(page);
    }
  };

  const totalPages = Math.ceil(reportCount / 20);
  const endPage = Math.min(startPage + 4, totalPages);


  const handleCountryChange = (value) => {
    setCountryCode(value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleStandardChange = (value) => {
    setStandard(value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleClearFilters = () => {
    setCurrentPage(1); 
    setCountryCode("");
    setStandard("");
    setQuery(""); 
    handlePageChange(1);
  };



  const handleSearchChange = (event) => {
    setQuery(event.target.value); // Update the search query state
    setCurrentPage(1); // Reset to first page on search change
  };


  const renderStars = (ranking) => {
    const fullStars = Math.floor(ranking);
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<li key={i}><IconStar /></li>);
      } else {
        stars.push(<li key={i}><IconStarOutline /></li>);
      }
    }
    return stars;
  };



  



  const renderPagination = () => {
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
        </li>
      );
    }
  
    return (
      <nav aria-label="..." id='pagination_row'>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
              <i className='fa fa-angle-double-left'></i>
            </a>
          </li>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
              <i className='icon-prev'></i>
            </a>
          </li>
          {pages}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
              <i className='icon-next'></i>
            </a>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
              <i className='fa fa-angle-double-right'></i>
            </a>
          </li>
        </ul>
      </nav>
    );
  };



  return (
    <>
      <Sidebar />
      <div id="header">
        <div className="logo">
          <div className="logo_icon">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="brand_name">Ranking </div>
        </div>

        <div className="header_table_filter">
         <div className="searchfield">
            <button className="btn_search"><i className="icon-search2"></i></button>
            <input 
              type="text" 
              className="filter_searchfield" 
              placeholder="Search" 
              value={query} // Bind the input value to the search query state
              onChange={handleSearchChange} // Handle change event
            />
          </div>

          <div className="dropdown_col_filter dropdown_select" style={{ width: 'auto', padding: 0 }}>
            <SelectPicker
              data={countryData}
              searchable={false}
              placeholder="Filter by Country"
              style={{ width: 180 }}
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>

          <div className="dropdown_col_filter dropdown_select" style={{ width: 'auto', padding: 0 }}>
            <SelectPicker
              data={standardData}
              searchable={false}
              placeholder="Filter by Standards"
              style={{ width: 180 }}
              onChange={handleStandardChange}
              value={standard}
            />
          </div>

          
          <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
      </div>
      <div className="container-fluid content pb-5">
  
        <div className="company_table_list table_ranking">
        {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className="table-responsive">

          {reportData && reportData.length > 0 ? (
            <table className="table">
           

              <thead>
                <tr>
                  <th>No</th>
                  <th>Ticker</th>
                  <th>Company Name</th>
                  <th>Country</th>
                  <th style={{ textAlign: 'center' }}>Compliance</th>
                  <th style={{ textAlign: 'center' }}>Score</th>
                  <th style={{ textAlign: 'center' }}>Ranking</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((company, index) => (
                  <tr key={index}>
                    <td style={{ fontWeight: 600 }}>{index + 1 + (currentPage - 1) * 20}</td>
                    <td>
                      <div className="	">
                        <span className="stock_lable" style={{ fontWeight: 600, textTransform: 'uppercase', width: 120 }}>{company.ticker}</span>
                      </div>
                    </td>
                    <td style={{ fontWeight: 600, textTransform: 'uppercase' }}>{company.name}</td>
                    <td style={{ color: '#000' }}>{company.country_name}</td>
                    <td style={{ textTransform: 'uppercase', color: '#000', textAlign: 'center' }}>{company.compliance}</td>
                    <td style={{ fontWeight: 600, textAlign: 'center' }}>{company.scoring}</td>
                    <td>
                      <ul className="rating_list">
                        {renderStars(company.star_rating)}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            ) : (
              <div className="table_zero_data">
                <img src={IconNoData} alt="No Data" />
                <p>No Reports Available</p>
              </div>
            )}
          </div>

          {/* {tableData && tableData.length > 0 && (
              <nav aria-label="..." className="clsPagination">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentPageProgress === 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        handleProgressPageChange(currentPageProgress - 1)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <i className="icon-prev"></i>
                    </a>
                  </li>
                  {[
                    ...Array(totalPagesProgress - startPageProgress + 1).keys(),
                  ].map((page) => (
                    <li
                      key={startPageProgress + page}
                      className={`page-item ${
                        currentPageProgress === startPageProgress + page
                          ? "active"
                          : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          handleProgressPageChange(startPageProgress + page)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {startPageProgress + page}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPageProgress === totalPagesProgress
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        handleProgressPageChange(currentPageProgress + 1)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <i className="icon-next"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            )} */}

{totalPages > 1 && renderPagination()}

</>    
)}
        </div>

      </div>
    </>
  );
};

export default Ranking;
