import React, { useState, useRef, useEffect } from 'react';
import { SelectPicker, Popover, Whisper, Button } from 'rsuite';

const TooltipTest = (props) => {
  const data = ['India', 'United State', 'Australia', 'Canada', 'Israel', 'Germany'].map(
    item => ({ label: item, value: item })
  );

  const [openReportPopover, setOpenReportPopover] = useState(false);

  const AssignData = [
    {
      label: 'Bunyod Rayimov',
      value: 'Bunyod',
      role: 'Junior Analyst',
      avatar: 'https://via.placeholder.com/30',
    },
    {
      label: 'Doniyor Akhmadjonov',
      value: 'Doniyor',
      role: 'Senior Analyst',
      avatar: 'https://via.placeholder.com/30',
    },
    {
      label: 'Yakhyo Sharofov',
      value: 'Yakhyo',
      role: 'Junior Analyst',
      avatar: 'https://via.placeholder.com/30',
    },
    {
      label: 'Azizbek Ortikov',
      value: 'Azizbek',
      role: 'Junior Analyst',
      avatar: 'https://via.placeholder.com/30',
    },
    // Add more data as needed
  ];

  const scrollContainerRef = useRef(null);

  const handleScroll = () => {
    setOpenReportPopover(false);
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <div ref={scrollContainerRef} style={{ width: 200, height: 200, overflowY: 'auto' }}>
        <Whisper
          placement="right"
          trigger="click"
          open={openReportPopover}
          onClose={() => setOpenReportPopover(false)}
          speaker={
            <Popover className="tooltip_form Rsuite_popover" preventOverflow>
              <h5>ODIN Investments SAE</h5>
              <div className="tooltip_form_inner">
                <label>Select Analyst</label>
                <div>
                  <SelectPicker
                    {...props}
                    data={AssignData}
                    searchable={false}
                    placeholder="Analyst Name"
                    menuClassName="rs-picker-select-menu rs-picker-popup selectPickerTMMain"
                    renderMenuItem={(label, item) => (
                      <div>
                        <img
                          src={item.avatar}
                          alt={item.label}
                          style={{ width: 44, height: 44, marginRight: 10 }}
                        />
                        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                          <div className="drop_title">{item.label}</div>
                          <div className="drop_designation">{item.role}</div>
                        </div>
                      </div>
                    )}
                    renderValue={(value, item) => item.label}
                  />
                </div>
              </div>
            </Popover>
          }
        >
          <Button className="btn_assign" onClick={() => setOpenReportPopover(true)}>
            Click
          </Button>
        </Whisper>
        <div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti quis tenetur, eaque voluptatibus doloribus ab sit ullam aperiam consequatur odit voluptates animi facilis nam nostrum velit ducimus magnam? Et, dolore.
        </div>
        <div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti quis tenetur, eaque voluptatibus doloribus ab sit ullam aperiam consequatur odit voluptates animi facilis nam nostrum velit ducimus magnam? Et, dolore.
        </div>
      </div>
    </>
  );
};

export default TooltipTest;
