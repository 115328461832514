import React, { useState,useEffect } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker } from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';
import { Link  } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos';

function ETFS() {

    const [tabName, setTabName] = useState('ETFs');

    useEffect(() => {
      document.title = tabName;
    }, [tabName]);
    
const data = ['Fixed Income', 'United State', 'Australia', 'Canada', 'Israel', 'Germany'].map(
    item => ({ label: item, value: item })
);

useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>ETFS</div>
        </div>

        <div className='header_table_filter'>
            <div className='searchfield'>
                <button className='btn_search'><i className='icon-search'></i></button>
                <input type='text' className='filter_searchfield' placeholder='Search by Ticker' />
            </div>

            <div className='dropdown_style filter_by_country'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ }}
                    placeholder="All Asset classes"
                    />
            </div>
            <div className='dropdown_style'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ }}
                    placeholder="All Compliance statuses"
                    />
            </div>
            <div className='dropdown_style'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ }}
                    placeholder="Both Publish Statuses"
                    />
            </div>

            <a href='#' className="btn_clear_filter">Clear Filters</a>
        </div>
    </div>
    <div className="container-fluid content pb-5">
      <div className='company_table_list' data-aos="fade-up" data-aos-duration="800">
        <div className='table-responsive'>
          <table className='table'>
              <thead>
                <tr>
                  <th style={{textAlign:'center'}}>Published</th>
                  <th style={{textAlign:'center'}}>Symbol</th>
                  <th style={{textAlign:'center'}}>ISIN</th>
                  <th>ETF Name</th>
                  <th style={{textAlign:'center'}}>Market</th>
                  <th style={{textAlign:'center'}}>Holdings</th>
                  <th style={{textAlign:'center'}}>Uncovered</th>
                  <th style={{textAlign:'center'}}>Compliance</th>
                  <th>Asset Class</th>
                  <th style={{textAlign:'center'}}>Non-COMPLIANT Ratio</th>
                  <th>Halal ratio</th>
                  <th style={{textAlign:'center'}}>Doubtful ratio</th>
                  <th style={{textAlign:'center'}}>Interest ratio</th>
                  <th>Debt ratio</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <td style={{textAlign:'center'}}>
                <label class="switchSmall ">
                    <input type="checkbox" />
                    <small></small>
                </label>
                </td>
                <td style={{textAlign:'center'}}>
                    <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                    </div>
                </td>
                 
                  <td style={{textAlign:'center'}}>US09789C8872</td>
                  <td>
                  <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                  </td>
                  <td style={{textAlign:'center'}}>US</td>
                  <td style={{textAlign:'center'}}>undefined</td>                  
                  <td>
                  <div className='text_ellipsis' style={{textAlign:'center'}}>
                    undefined
                  </div>    
                  </td>
                  <td style={{textAlign:'center'}}><span className='not_halal_tag tag_td'>Non-COMPLIANT</span></td>
                  <td>Fixed Income</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 

                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='uncover_tag tag_td'>Uncovered</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 
                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" checked />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='halal_tag tag_td'>COMPLIANT</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr>

                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" checked />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='not_halal_tag tag_td'>Non-COMPLIANT</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr>
                <tr>
                <td style={{textAlign:'center'}}>
                <label class="switchSmall ">
                    <input type="checkbox" />
                    <small></small>
                </label>
                </td>
                <td style={{textAlign:'center'}}>
                    <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                    </div>
                </td>
                 
                  <td style={{textAlign:'center'}}>US09789C8872</td>
                  <td>
                     <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                  </td>
                  <td style={{textAlign:'center'}}>US</td>
                  <td style={{textAlign:'center'}}>undefined</td>                  
                  <td>
                  <div className='text_ellipsis' style={{textAlign:'center'}}>
                    undefined
                  </div>    
                  </td>
                  <td style={{textAlign:'center'}}><span className='not_halal_tag tag_td'>Non-COMPLIANT</span></td>
                  <td>Fixed Income</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 

                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='uncover_tag tag_td'>Uncovered</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 
                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" checked />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='halal_tag tag_td'>COMPLIANT</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr>

                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" checked />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='not_halal_tag tag_td'>Non-COMPLIANT</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr>
                <tr>
                <td style={{textAlign:'center'}}>
                <label class="switchSmall ">
                    <input type="checkbox" />
                    <small></small>
                </label>
                </td>
                <td style={{textAlign:'center'}}>
                    <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                    </div>
                </td>
                 
                  <td style={{textAlign:'center'}}>US09789C8872</td>
                  <td>
                     <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                  </td>
                  <td style={{textAlign:'center'}}>US</td>
                  <td style={{textAlign:'center'}}>undefined</td>                  
                  <td>
                  <div className='text_ellipsis' style={{textAlign:'center'}}>
                    undefined
                  </div>    
                  </td>
                  <td style={{textAlign:'center'}}><span className='not_halal_tag tag_td'>Non-COMPLIANT</span></td>
                  <td>Fixed Income</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 

                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='uncover_tag tag_td'>Uncovered</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 
                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" checked />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='halal_tag tag_td'>COMPLIANT</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr>

                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" checked />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='not_halal_tag tag_td'>Non-COMPLIANT</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr>
                <tr>
                <td style={{textAlign:'center'}}>
                <label class="switchSmall ">
                    <input type="checkbox" />
                    <small></small>
                </label>
                </td>
                <td style={{textAlign:'center'}}>
                    <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                    </div>
                </td>
                 
                  <td style={{textAlign:'center'}}>US09789C8872</td>
                  <td>
                     <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                  </td>
                  <td style={{textAlign:'center'}}>US</td>
                  <td style={{textAlign:'center'}}>undefined</td>                  
                  <td>
                  <div className='text_ellipsis' style={{textAlign:'center'}}>
                    undefined
                  </div>    
                  </td>
                  <td style={{textAlign:'center'}}><span className='not_halal_tag tag_td'>Non-COMPLIANT</span></td>
                  <td>Fixed Income</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 

                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='uncover_tag tag_td'>Uncovered</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 
                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" checked />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='halal_tag tag_td'>COMPLIANT</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr>

                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" checked />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='not_halal_tag tag_td'>Non-COMPLIANT</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr>
                <tr>
                <td style={{textAlign:'center'}}>
                <label class="switchSmall ">
                    <input type="checkbox" />
                    <small></small>
                </label>
                </td>
                <td style={{textAlign:'center'}}>
                    <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                    </div>
                </td>
                 
                  <td style={{textAlign:'center'}}>US09789C8872</td>
                  <td>
                     <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                  </td>
                  <td style={{textAlign:'center'}}>US</td>
                  <td style={{textAlign:'center'}}>undefined</td>                  
                  <td>
                  <div className='text_ellipsis' style={{textAlign:'center'}}>
                    undefined
                  </div>    
                  </td>
                  <td style={{textAlign:'center'}}><span className='not_halal_tag tag_td'>Non-COMPLIANT</span></td>
                  <td>Fixed Income</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                  <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 

                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='uncover_tag tag_td'>Uncovered</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 
                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" checked />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='halal_tag tag_td'>COMPLIANT</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr>

                <tr>
                    <td style={{textAlign:'center'}}>
                    <label class="switchSmall ">
                        <input type="checkbox" checked />
                        <small></small>
                    </label>
                    </td>
                    <td style={{textAlign:'center'}}>
                        <div className='company_name_td'>
                        <span className='stock_lable'>543375.BO</span>
                        </div>
                    </td>
                    
                    <td style={{textAlign:'center'}}>US09789C8872</td>
                    <td>
                         <Link to="/ETFHolding" className='etf_name text_ellipsis'>BondBloxx CCC-Rated USD High...</Link>
                    </td>
                    <td style={{textAlign:'center'}}>US</td>
                    <td style={{textAlign:'center'}}>undefined</td>                  
                    <td>
                    <div className='text_ellipsis' style={{textAlign:'center'}}>
                        undefined
                    </div>    
                    </td>
                    <td style={{textAlign:'center'}}><span className='not_halal_tag tag_td'>Non-COMPLIANT</span></td>
                    <td>Fixed Income</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                    <td style={{textAlign:'center'}}>Undefined%</td>
                </tr> 
                
              </tbody> 
          </table>
          <nav aria-label="..." id='pagination_row'>
            <ul class="pagination">
                <li class="page-item disabled">
                <a class="page-link"><i className='icon-prev'></i></a>
                </li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item active" aria-current="page">
                <a class="page-link" href="#">2</a>
                </li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">4</a></li>
                <li class="page-item"><a class="page-link" href="#">5</a></li>
                <li class="page-item">
                <a class="page-link" href="#"><i className='icon-next'></i></a>
                </li>
            </ul>
            </nav>
        </div>

            
      </div>
    </div>
    </>
  );
}

export default ETFS;
