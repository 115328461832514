import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker } from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';

function CompanyProfileReports() {

  const [tabName, setTabName] = useState('Company Profile Reports');
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
    useEffect(() => {
      document.title = tabName;
    }, [tabName]);


const data = ['Quarter', 'Second Quarter', 'Third Quarter'].map(
  item => ({ label: item, value: item })
);

  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>FLEETWOOD LTD (FWD.AX) REPORTS</div>
        </div>
    </div>
    <div className="container-fluid content pb-5">
      <div className='company_table_list'>
        <div className='table-responsive'>
          <table className='table'>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Filling</th>
                  <th>Report End</th>
                  <th>Last Updated</th>
                  <th style={{textAlign:'center'}}>Ticker</th>
                  <th>Main Ticker</th>
                  <th>ISIN</th>
                  <th>Compliance</th>
                  <th>Publish</th>
                  <th>Doc</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='halal_tag tag_td'>Halal</span></td>                                    
                  <td><span className='status_unpublish'><i className="icon-cross"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>  
                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='not_halal_tag tag_td'>Not Halal</span></td>                                    
                  <td><span className='status_active'><i className="icon-check"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='halal_tag tag_td'>Halal</span></td>                                    
                  <td><span className='status_unpublish'><i className="icon-cross"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>  
                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='not_halal_tag tag_td'>Not Halal</span></td>                                    
                  <td><span className='status_active'><i className="icon-check"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr> 

                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='halal_tag tag_td'>Halal</span></td>                                    
                  <td><span className='status_unpublish'><i className="icon-cross"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>  
                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='not_halal_tag tag_td'>Not Halal</span></td>                                    
                  <td><span className='status_active'><i className="icon-check"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='halal_tag tag_td'>Halal</span></td>                                    
                  <td><span className='status_unpublish'><i className="icon-cross"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>  
                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='not_halal_tag tag_td'>Not Halal</span></td>                                    
                  <td><span className='status_active'><i className="icon-check"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='halal_tag tag_td'>Halal</span></td>                                    
                  <td><span className='status_unpublish'><i className="icon-cross"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>  
                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='not_halal_tag tag_td'>Not Halal</span></td>                                    
                  <td><span className='status_active'><i className="icon-check"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='halal_tag tag_td'>Halal</span></td>                                    
                  <td><span className='status_unpublish'><i className="icon-cross"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>  
                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='not_halal_tag tag_td'>Not Halal</span></td>                                    
                  <td><span className='status_active'><i className="icon-check"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='halal_tag tag_td'>Halal</span></td>                                    
                  <td><span className='status_unpublish'><i className="icon-cross"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>  
                <tr>
                  <td>2024</td>
                  <td>Second_Quarter</td>
                  <td>2023-06-30</td>
                  <td>2024-10-01</td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td><span className='stock_lable'>FWD.AX</span></td>
                  <td>AU000000FWD0</td>
                  <td><span className='not_halal_tag tag_td'>Not Halal</span></td>                                    
                  <td><span className='status_active'><i className="icon-check"></i></span></td>                
                  <td>Original</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal">
                        <i className='icon-duplicate'></i>
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                    </div>
                  </td>
                </tr>
              </tbody> 
          </table>
        </div>

        <nav aria-label="..." id='pagination_row'>
            <ul class="pagination">
                <li class="page-item disabled">
                <a class="page-link"><i className='icon-prev'></i></a>
                </li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item active" aria-current="page">
                <a class="page-link" href="#">2</a>
                </li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">4</a></li>
                <li class="page-item"><a class="page-link" href="#">5</a></li>
                <li class="page-item">
                <a class="page-link" href="#"><i className='icon-next'></i></a>
                </li>
            </ul>
            </nav>
      </div>
    </div>

    {/* Modal */}
    <div class="modal fade duplicate_form_modal" id="DuplicateFormModal" tabindex="-1" aria-labelledby="DuplicateFormModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5">FLEETWOOD LTD (FWD.AX) REPORTS</h1>
        </div>
        <div class="modal-body">
            <div className='duplicate_form_content'>
                <div className='duplicate_form_row'>
                    <div className='duplicate_form_col'>
                        <label>Report Source</label>
                        <input type='text' className='form-control' placeholder='YYYY' />
                    </div>

                    <div className='duplicate_form_col'>
                        <label>Filling Type</label>
                        <div className='select_drop_picker'>
                            <SelectPicker
                            data={data}
                            searchable={false}
                            style={{ width: '100%' }}
                            placeholder="Quarter"
                            />
                        </div>
                    </div>
                </div>

                <div class="btn__group">
                    <button type="button" class="btn_cancel" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn_duplicate">Duplicate</button>
                </div>
                
            </div>
        </div>
        
        </div>
    </div>
    </div>
    </>
  );
}

export default CompanyProfileReports;
