import React, { useState, useEffect, useCallback } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Whisper,Tooltip } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { Link, useNavigate } from "react-router-dom";
import iconReportUsers from "../images/iconReportUsers.svg";
import { right } from '@popperjs/core';
import IconJson from "../images/IconJSONAction.svg";
import IconReportAction from "../images/IconReportAction.svg";
import debounce from 'lodash.debounce';
import Open_Link_Icon from "../images/Open_link_round.svg";

function ComplianceReports() {
  const [tabName, setTabName] = useState('Compliance Report');
  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const frontEndLink = process.env.REACT_APP_MUSAFFA_LINK;
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [statusId, setStatusId] = useState("");

  const [copiedText, setCopiedText] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const userid = localStorage.getItem("novauserid");


  //Country List
  useEffect(() => {
    const fetchCountryList = async () => {
      const url = `${apiEndPoint}/get_country_list`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedCountryData = data.data.map((country) => ({
          label: country.country_name,
          value: country.country_code,
        }));

        setCountryData(formattedCountryData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchCountryList();
  }, []);

  // Compliance List
  useEffect(() => {
    const fetchComplianceStatusList = async () => {
      const url = `${apiEndPoint}/get_compliance_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedComplianceStatusData = data.data.map((complaince) => ({
          label: complaince.name,
          value: complaince.id,
        }));

        setComplianceData(formattedComplianceStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchComplianceStatusList();
  }, []);

  //Status List
  useEffect(() => {
    const fetchStatusList = async () => {
      const url = `${apiEndPoint}/get_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedStatusData = data.data.map((status) => ({
          label: status.name,
          value: status.id,
        }));

        setStatusData(formattedStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchStatusList();
  }, []);


  const fetchGlobalReport = async () => {
    setLoading(true);

        try {
      const apiUrl = `${apiEndPoint}/get_compliance_report`;
      const requestData = {
        data: {
          user_id: userid,
          limit: "20",
          page: currentPage.toString(),
          q:searchInput || "",
          country_code: countryCode || "",
          publish_un_publish:statusId,
          status: complianceId,
        }
      };
      console.log(JSON.stringify(requestData));
      
      //console.log(JSON.stringify(requestData));
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      const jsonData = await response.json();
      const { status, message, count, data } = jsonData.data;

      if (status === "success") {
        setLoading(false);
        setReportCount(count);
        setReportData(data);
      } else {
        setLoading(false);
        setReportData("");
        setReportCount("");
        console.error("Error: ", message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
      fetchGlobalReport();
    }, 500),
    [searchInput] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [searchInput]);



  useEffect(() => {
    fetchGlobalReport();
  }, [currentPage, countryCode,complianceId,statusId]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (page >= startPage + 5) {
      setStartPage(page - 4);
    } else if (page < startPage) {
      setStartPage(page);
    }
  };

  const totalPages = Math.ceil(reportCount / 20);
  const endPage = Math.min(startPage + 4, totalPages);

  const data = ['India', 'United States', 'Australia'].map(
    item => ({ label: item, value: item })
  );

  const data2 = ['Quarter', 'Second Quarter', 'Third Quarter'].map(
    item => ({ label: item, value: item })
  );

  const [copied, setCopied] = useState(false);

  const copyText = (text) => {
  if (text !== copiedText) {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    setCopiedText(text);
    setTimeout(() => {
      setCopiedText(null);
    }, 2000);
  }
};


 //Table Loader 
 
//  useEffect(() => {
//      // Simulate an API call or data loading delay
//      setTimeout(() => setLoading(false), 2000); // Simulate 2 seconds loading time
//    }, []);



const handleSearch = (event) => {
  setSearchInput(event.target.value);
  const inputValue = event.target.value;
  //if (inputValue.length >= 3) {
  setCurrentPage(1); // Reset to the first page for new search
  //}
};


const handleCountryChange = (value) => {
  setCountryCode(value);
};

const handleComplianceChange = (value) => {
  setComplianceId(value);
};

const handleStatusChange = (value) => {
  setStatusId(value);
};


const handleComplianceReport = (encryptJSON) => {
  navigate(`/ComplianceMerlineView/${encryptJSON}`);
};

const handleClearFilters = () => {
  setCurrentPage(1);
  setSearchInput('');
  setCountryCode(''); 
  setStatusId('');
  setComplianceId('');
};


const renderPagination = () => {
  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    const isFourDigitsOrMore = i >= 1000;
    pages.push(
      // <li key={i} className={`page-item extra_Pagination_gap ${i === currentPage ? 'active' : ''}`}>
      <li key={i} className={`page-item ${isFourDigitsOrMore ? 'extra_Pagination_gap' : ''} ${i === currentPage ? 'active' : ''}`} >
        <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
      </li>
    );
  }

  return (
    <nav aria-label="..." id='pagination_row'>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
            <i className='fa fa-angle-double-left'></i>
          </a>
        </li>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
            <i className='icon-prev'></i>
          </a>
        </li>
        {pages}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
            <i className='icon-next'></i>
          </a>
        </li>
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
            <i className='fa fa-angle-double-right'></i>
          </a>
        </li>
      </ul>
    </nav>
  );
};




  return (
    <>
      <Sidebar />
      <div id='header'>
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Compliance Report</div>
        </div>

        <div className='header_table_filter'>
          <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search'  onChange={handleSearch}   value={searchInput}/>
          </div>

          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={statusData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="Both Status"
              onChange={handleStatusChange}
              value={statusId}
            />
          </div>
          <div className='dropdown_col_filter dropdown__select'>
          <SelectPicker
              data={countryData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Country"
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>
          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={complianceData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="All Compliance"
              onChange={handleComplianceChange}
              value={complianceId}
            />
          </div>

          <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>

        </div>
      </div>
      <div className="container-fluid content pb-5">
        <div className='company_table_list'>
        {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                <th>Stock/Company</th>
                  <th style={{textAlign:'center'}}>ISIN</th>
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover"  speaker={<Tooltip>Combined Country and Exchange information</Tooltip>}> 
                  <th style={{cursor:'pointer'}}>Country</th>
                  </Whisper>
                  {/* <th>Exch</th> */}
                  <th style={{textAlign:'center'}}>Compliance</th>
                  <th style={{textAlign:'center'}}>Username</th>
                  <th style={{textAlign:'center'}}>Status</th>
                  <th style={{textAlign:'center'}}>Updated</th>
                  {/* <th>Year</th> */}
                  <th style={{textAlign:'center'}}>Quarter</th>
                  <th style={{textAlign:'center'}}>Form</th>
                  <th style={{textAlign:right}}>Actions</th>
                </tr>
              </thead>
              <tbody>
              {reportData.length > 0 ? (
              reportData.map(item => (
                <tr key={item.id} style={{textAlign:'center'}}>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>{item.stock_name}</span>
                    {/* style={{overflow:'hidden', textOverflow:'ellipsis',maxWidth:120,display:'block'}} */}
                    <div className="clogo">
                    <img src={item.logo} />
                  </div>
                    <span >{item.name}</span>
                  </div>
                </td>
                <td>
                  <div className="table_td_big_content_url" onClick={() => copyText(item.isin)}>
                    {item.isin}
                    {copied && <div className='copied_text'>Copied!</div>}
                  </div>
                </td>
                <td style={{textAlign:'left'}}>{item.country} - {item.exchange_symbol}</td>
                {/* <td>{item.exchange_symbol}</td> */}
                <td style={{textAlign:'center'}}>
                <span className={ item.AAOIFI_status === 'NON_COMPLIANT' ? 'not_halal_tag tag_td' : item.AAOIFI_status === 'COMPLIANT' ? 'halal_tag tag_td' : item.AAOIFI_status === 'QUESTIONABLE' ? 'doughtful_tag tag_td' : 'tag_td' } style={{width:75, margin:'auto'}} >
                {item.AAOIFI_status === 'NON_COMPLIANT' ? 'NCMPT' : item.AAOIFI_status === 'COMPLIANT' ? 'CMPT' : item.AAOIFI_status === 'QUESTIONABLE' ? 'DBTF' : item.AAOIFI_status}
                </span>
                </td>
                <td>
                <Whisper placement="top" controlId="control-id-hover" trigger="hover"  speaker={<Tooltip>{item.user_name}</Tooltip>}> 
                  <div  style={{cursor:'pointer'}}>
                   {item.short_user_name}
                  </div>
                  </Whisper>
                </td>
                
                
                <td><span className={item.publish_un_publish === 'PUBLISH' ? 'status_active' : item.publish_un_publish === 'UN_PUBLISH' ? 'status_unpublish' : '' }><i className={ item.publish_un_publish === 'PUBLISH' ? 'icon-check' : item.publish_un_publish === 'UN_PUBLISH' ? 'icon-cross' : '' }></i></span></td>
                <td>{item.updated_date}</td>
                {/* <td>{item.year}</td> */}
                <td style={{textAlign:'center'}}>
                {item.year} - {item.quarter === 'FIRST_QUARTER' ? 'Q1' : item.quarter === 'SECOND_QUARTER' ? 'Q2' : item.quarter === 'THIRD_QUARTER' ? 'Q3' : item.quarter}
                </td>
                <td>{item.form}</td>
                <td>
                  <div className='action_row' style={{justifyContent:'flex-end'}}>
                  {item.form.includes('New') && (
                <Link to={`/report/${item.encryptJSON}`} className='btn_create_report'>
                  Show Report
                </Link>
              )}
                    <Link to={`/ComplianceProfileReports/${item.stock_name}`} target='_blank' className="btn_icon_action">
                        <img src={IconReportAction} height="28" />
                    </Link>

                    <Link to={`${apiEndPoint}/get_compliance_data?main_ticker=&stock_name=&id=${item.id}`} target="_blank" className="btn_icon_action">
                        <img src={IconJson} height="28" />
                        
                    </Link>
                    <Link to={`${frontEndLink}/${item.company_symbol}`} target="_blank" className="btn_icon_action">
                        <img src={Open_Link_Icon} style={{ cursor: "pointer", height:28 }} />
                       </Link>

                  </div>
                </td>
              </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

          {totalPages > 1 && renderPagination()}
        </>    
)}
        </div>
      </div>

      {/* Modal */}
      <div className="modal fade duplicate_form_modal" id="CreateReportModal" tabIndex="-1" aria-labelledby="DuplicateFormModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Igene Biotechnology Inc</h1>
            </div>
            <div className="modal-body">
              <div className='duplicate_form_content'>
                <div className='duplicate_form_row'>
                  <div className='duplicate_form_col'>
                    <label>Report Source</label>
                    <input type='text' className='form-control' placeholder='YYYY' />
                  </div>

                  <div className='duplicate_form_col'>
                    <label>Filling Type</label>
                    <div className='select_drop_picker'>
                      <SelectPicker
                        data={data2}
                        searchable={false}
                        style={{ width: '100%' }}
                        placeholder="Quarter"
                      />
                    </div>
                  </div>
                </div>

                <div className="btn__group">
                  <button type="button" className="btn_cancel" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" className="btn_duplicate btn_add_new_report">Add New Report</button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default ComplianceReports;
