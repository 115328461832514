import React, { useState, useRef, useEffect } from "react";
import { Menu, MenuItem, ProSidebar, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, Typography } from "@mui/material";

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [openSubMenu, setOpenSubMenu] = useState('');
  const sidebarRef = useRef(null);
  const roleName = localStorage.getItem('novaRolename');
  const currentLocation = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsCollapsed(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Set the open submenu based on the current location
    if (currentLocation.pathname.startsWith("/compliance-reports") || currentLocation.pathname.startsWith("/company-profile") || currentLocation.pathname.startsWith("/StockReport")) {
      setOpenSubMenu('Stocks');
    } else if (currentLocation.pathname.startsWith("/ETFS") || currentLocation.pathname.startsWith("/ETFHoldingsBasket")) {
      setOpenSubMenu('ETFS');
    } else if (currentLocation.pathname.startsWith("/MarketsSettings") || currentLocation.pathname.startsWith("/Empty-ISINS") || currentLocation.pathname.startsWith("/UsersandRoles") ) {
      setOpenSubMenu('Settings');
    } else if (currentLocation.pathname.startsWith("/Logout")) {
      setOpenSubMenu('Account');
    } else {
      setOpenSubMenu('');
    }
  }, [currentLocation]);

  const handleMouseEnter = () => {
    setIsCollapsed(false);
  };

  const handleMouseLeave = () => {
    setIsCollapsed(false);
  };

  const handleMouseMove = () => {
    setIsCollapsed(false);
  };

  const handleSidebarClick = (event) => {
    event.stopPropagation();
  };

  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsCollapsed(true); // Close the sidebar drawer
    }
  };

  // Add event listener to handle outside clicks
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <>
      <Box onMouseMove={handleMouseMove}>
        <div id="sidebarTrigger" onClick={() => setIsCollapsed(!isCollapsed)}></div>
        <ProSidebar
          collapsed={isCollapsed}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={sidebarRef}
          styles={{
            transition: 'transform 0.10s ease', // Adjust the transition duration here (0.5s)
            transform: isCollapsed ? 'translateX(-260px)' : 'translateX(0)'
          }}
        >
          <Menu iconShape="square">
            <Box>
              <Typography
                variant="h6"
                sx={{ m: "15px 0 5px 20px" }}
              >
                Mains ({roleName})
              </Typography>

              <MenuItem 
                className={selectedMenuItem === 'TaskManager' ? 'active' : ''} 
                onClick={() => { setSelectedMenuItem('TaskManager'); setOpenSubMenu('TaskManager'); }}
                active={currentLocation.pathname === "/TaskManager"}
              >
                <i className="icon-union"></i> <Link to="/TaskManager">Task Manager</Link>
              </MenuItem>
              <MenuItem 
                className={selectedMenuItem === 'Ranking' ? 'active' : ''} 
                onClick={() => { setSelectedMenuItem('Ranking'); setOpenSubMenu('Ranking'); }}
                active={currentLocation.pathname === "/Ranking"}
              >
                <i className="icon-ScoringRanking"></i> <Link to="/Ranking">Scoring & Ranking</Link>
              </MenuItem>

              <MenuItem 
                className={selectedMenuItem === 'Dashboard' ? 'active' : ''} 
                onClick={() => { setSelectedMenuItem('Dashboard'); setOpenSubMenu('Dashboard'); }}
                active={currentLocation.pathname === "/Dashboard"}
              >
                <i className="icon-home"></i> Dashboard
              </MenuItem>
              
              <SubMenu 
                title={<><i className="custom-icon icon-graph"></i> Stocks</>}
                open={openSubMenu === 'Stocks'} 
                onOpen={() => setOpenSubMenu('Stocks')} 
                onClick={() => setOpenSubMenu('Stocks')}
              >
                <MenuItem 
                  className={selectedMenuItem === 'Compliance' ? 'active' : ''} 
                  onClick={() => { setSelectedMenuItem('Compliance'); setOpenSubMenu('Stocks'); }}
                  active={currentLocation.pathname === "/compliance-reports"}
                >
                  <Link to="/compliance-reports">Compliance Reports</Link>
                </MenuItem>
                <MenuItem  
                  className={selectedMenuItem === 'Company' ? 'active' : ''} 
                  onClick={() => { setSelectedMenuItem('Company'); setOpenSubMenu('Stocks'); }}
                  active={currentLocation.pathname === "/company-profile"}
                >
                  <Link to="/company-profile">Company Profile</Link>
                </MenuItem>
                <MenuItem  
                  className={selectedMenuItem === 'Report' ? 'active' : ''} 
                  onClick={() => { setSelectedMenuItem('Report'); setOpenSubMenu('Stocks'); }}
                  active={currentLocation.pathname === "/StockReport"}
                >
                  <Link to="/StockReport">Reports</Link>
                </MenuItem>                        
              </SubMenu>

              {/* <SubMenu 
                title={<><i className="custom-icon icon-ETF"></i> ETFS</>}
                open={openSubMenu === 'ETFS'} 
                onOpen={() => setOpenSubMenu('ETFS')} 
                onClick={() => setOpenSubMenu('ETFS')}
              >
                <MenuItem 
                  className={selectedMenuItem === 'ETFs' ? 'active' : ''} 
                  onClick={() => { setSelectedMenuItem('ETFs'); setOpenSubMenu('ETFS'); }}
                  active={currentLocation.pathname === "/ETFS"}
                >
                  <Link to="/ETFS">ETFs</Link>
                </MenuItem>
                <MenuItem 
                  className={selectedMenuItem === 'HoldingBasket' ? 'active' : ''} 
                  onClick={() => { setSelectedMenuItem('HoldingBasket'); setOpenSubMenu('ETFS'); }}
                  active={currentLocation.pathname === "/ETFHoldingsBasket"}
                >
                  <Link to="/ETFHoldingsBasket">ETF Holding Basket</Link>
                </MenuItem>                                     
              </SubMenu>  */}
            
              <MenuItem 
                className={selectedMenuItem === 'StockRequests' ? 'active' : ''} 
                onClick={() => { setSelectedMenuItem('StockRequests'); setOpenSubMenu('StockRequests'); }}
                active={currentLocation.pathname === "/StockRequests"}
              >
                <i className="fa fa-file-text-o"></i> <Link to="/StockRequests">Stock Requests</Link>
              </MenuItem> 
              <MenuItem 
                className={selectedMenuItem === 'StockCoverage' ? 'active' : ''} 
                onClick={() => { setSelectedMenuItem('StockCoverage'); setOpenSubMenu('StockCoverage'); }}
                active={currentLocation.pathname === "/StockCoverage"}
              >
                <i className="fa fa-file-text-o"></i> <Link to="/StockCoverage">Stock Coverage</Link>
              </MenuItem> 
              
              <Typography
                variant="h6"
                sx={{ m: "15px 0 5px 20px" }}
                className="menu_setting_section"
              >
                Settings
              </Typography>
              <SubMenu 
                title={<><i className="custom-icon icon-user"></i> Account</>}
                open={openSubMenu === 'Account'} 
                onOpen={() => setOpenSubMenu('Account')} 
                onClick={() => setOpenSubMenu('Account')}
              >
                <MenuItem 
                  className={selectedMenuItem === 'Logout' ? 'active' : ''} 
                  onClick={() => { setSelectedMenuItem('Logout'); setOpenSubMenu('Logout'); localStorage.removeItem('isLoggedIn'); }}
                  active={currentLocation.pathname === "/"}
                >
                  <Link to="/">Logout</Link>
                </MenuItem>                                   
              </SubMenu> 

              <SubMenu title={<><i className="custom-icon icon-setting"></i> Settings</>} open={openSubMenu === 'Settings'} onOpen={() => setOpenSubMenu('Settings')} onClick={() => setOpenSubMenu('Settings')} >
           
              {/* {roleName === 'MERLIN_ADMIN' && (
              <MenuItem className={selectedMenuItem === 'UsersandRoles' ? 'active' : ''} onClick={() => { setSelectedMenuItem('Settings'); setOpenSubMenu('Settings'); }} active={currentLocation.pathname === "/UsersandRoles"} >
                  <Link to="/UsersandRoles">Users And Roles</Link>
                </MenuItem> 
              )} */}

                <MenuItem className={selectedMenuItem === 'MarketsSettings' ? 'active' : ''} onClick={() => { setSelectedMenuItem('Settings'); setOpenSubMenu('Settings'); }} active={currentLocation.pathname === "/MarketsSettings"} >
                  <Link to="/MarketsSettings">Markets Settings</Link>
                </MenuItem> 
                <MenuItem className={selectedMenuItem === 'Empty-ISINS' ? 'active' : ''} onClick={() => { setSelectedMenuItem('Settings'); setOpenSubMenu('Settings'); }} active={currentLocation.pathname === "/Empty-ISINS"} >
                  <Link to="/Empty-ISINS">Finnhub-Musaffa Data</Link>
                </MenuItem>                                   
              </SubMenu> 
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
    </>
  );
};

export default Sidebar;
