import React, { useState, useEffect, useCallback } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Whisper, Tooltip } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { Link } from "react-router-dom";
import debounce from 'lodash.debounce';

function StockReport() {
  const [tabName, setTabName] = useState('Reports');
  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);
  const roleName = localStorage.getItem("novaRolename");

  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [statusId, setStatusId] = useState("");

  const [copiedText, setCopiedText] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const userid = localStorage.getItem("novauserid");
  //Country List
  useEffect(() => {
    const fetchCountryList = async () => {
      const url = `${apiEndPoint}/get_country_list`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedCountryData = data.data.map((country) => ({
          label: country.country_name,
          value: country.country_code,
        }));

        setCountryData(formattedCountryData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchCountryList();
  }, []);

  // Compliance List
  useEffect(() => {
    const fetchComplianceStatusList = async () => {
      const url = `${apiEndPoint}/get_compliance_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedComplianceStatusData = data.data.map((complaince) => ({
          label: complaince.name,
          value: complaince.id,
        }));

        setComplianceData(formattedComplianceStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchComplianceStatusList();
  }, []);

  //Status List
  useEffect(() => {
    const fetchStatusList = async () => {
      const url = `${apiEndPoint}/get_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedStatusData = data.data.map((status) => ({
          label: status.name,
          value: status.id,
        }));

        setStatusData(formattedStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchStatusList();
  }, []);

  const fetchGlobalReport = async () => {
    setLoading(true);

    try {
      const apiUrl = `${apiEndPoint}/get_company_report`;
      const requestData = {
        data: {
          user_id: userid,
          limit: "20",
          page: currentPage.toString(),
          q:searchInput || "",
          country_code: countryCode || "",
          AAOIFI_status:complianceId,
          status: statusId,
        }
      };
      console.log(JSON.stringify(requestData));
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      const jsonData = await response.json();
      const { status, message, count, data } = jsonData.data;
      if (status === "success") {
        setLoading(false);
        setReportCount(count);
        setReportData(data);
      } else {
        console.error("Error: ", message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
      fetchGlobalReport();
    }, 500),
    [searchInput] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [searchInput]);


  useEffect(() => {
    fetchGlobalReport();
  }, [currentPage, countryCode, complianceId, statusId]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (page >= startPage + 5) {
      setStartPage(page - 4);
    } else if (page < startPage) {
      setStartPage(page);
    }
  };

  const totalPages = Math.ceil(reportCount / 20);
  const endPage = Math.min(startPage + 4, totalPages);

  const data = ['India', 'United States', 'Australia'].map(
    item => ({ label: item, value: item })
  );

  const data2 = ['Quarter', 'Second Quarter', 'Third Quarter'].map(
    item => ({ label: item, value: item })
  );

  const [copied, setCopied] = useState(false);

  const copyText = (text) => {
  if (text !== copiedText) {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    setCopiedText(text);
    setTimeout(() => {
      setCopiedText(null);
    }, 2000);
  }
};


 //Table Loader 
 
//  useEffect(() => {
//      // Simulate an API call or data loading delay
//      setTimeout(() => setLoading(false), 2000); // Simulate 2 seconds loading time
//    }, []);



const handleSearch = (event) => {
  setSearchInput(event.target.value);
  const inputValue = event.target.value;
  //if (inputValue.length >= 3) {
  setCurrentPage(1); // Reset to the first page for new search
  //}
};


const handleCountryChange = (value) => {
  setCountryCode(value);
};

const handleComplianceChange = (value) => {
  setComplianceId(value);
};

const handleStatusChange = (value) => {
  setStatusId(value);
};

const handleClearFilters = () => {
  setCurrentPage(1);
  setCountryCode("");
  setSearchInput("");
  setComplianceId("");
  setStatusId("");
};


const renderPagination = () => {
  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
        <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
      </li>
    );
  }



  return (
    <nav aria-label="..." id='pagination_row'>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
            <i className='fa fa-angle-double-left'></i>
          </a>
        </li>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
            <i className='icon-prev'></i>
          </a>
        </li>
        {pages}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
            <i className='icon-next'></i>
          </a>
        </li>
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
            <i className='fa fa-angle-double-right'></i>
          </a>
        </li>
      </ul>
    </nav>
  );
};

// PAGINATION
const PagingData = ['10/page', '20/page', '50/page', '100/page'].map(
  item => ({ label: item, value: item })
);




  return (
    <>
      <Sidebar />
      <div id='header'>
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Reports</div>
        </div>

        <div className='header_table_filter'>
          <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search'  onChange={handleSearch}   value={searchInput}/>
          </div>

          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={statusData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="Both Status"
              onChange={handleStatusChange}
              value={statusId}
            />
          </div>
          <div className='dropdown_col_filter dropdown__select'>
          <SelectPicker
              data={countryData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Country"
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>
          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={complianceData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="All Compliance"
              onChange={handleComplianceChange}
              value={complianceId}
            />
          </div>

          <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
          
        </div>
      </div>
      <div className="container-fluid content pb-5">
        <div className='company_table_list'>
        {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
              <tr>
                  <th>Stock and Company name</th>
                  <th>Username</th>
                  <th style={{textAlign:'center'}}>ISIN</th>
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover"  speaker={<Tooltip>Combined Country and Exchange information</Tooltip>}> 
                  <th style={{textAlign:'center', cursor:'pointer'}}>Country</th>
                  </Whisper>

                  
                  <th style={{textAlign:'center'}}>Quarter</th>
                  {/* <th style={{textAlign:'center'}}>Year</th> */}
                  <th>Report Stat</th>
                  <th style={{textAlign:'center'}}>Compliance</th>
                  <th style={{textAlign:'center'}}>Publish</th>
                  <th style={{textAlign:'center'}}>Actions</th>
                </tr>
              </thead>
              <tbody>
              {reportData.length > 0 ? (
  reportData.map(item => (
    <tr key={item.id}>
    <td>
      <div className='company_name_td'>
        
          <span className='stock_lable'>{item.company_symbol}</span>
          <div className="clogo">
                    <img src={item.logo} />
                  </div>
        {item.name}
      </div>
    </td>
    <td>{item.user_name}</td>
    <td style={{textAlign:'center'}}>{item.isin}</td>
    <td style={{textAlign:'center'}}>{item.country} - {item.exchange_symbol}</td>
    <td style={{textAlign:'center'}}>{item.year} - Q{item.quarter}</td>
    {/* <td style={{textAlign:'center'}}>{item.year}</td> */}
    <td>{item.status_name}</td>
    <td style={{textAlign:'center'}}>
    <span style={{margin:'auto', width:75}} className={`tag_td ${ item.AAOIFI_status === "0" || item.AAOIFI_status === null ? '' : item.AAOIFI_status === "1" ? 'halal_tag' : item.AAOIFI_status === "2" ? 'not_halal_tag' : item.AAOIFI_status === "3" ? 'doughtful_tag' : item.AAOIFI_status === "4" ? 'uncover_tag' : 'doubtful_tag' }`} >
    {item.AAOIFI_status_name == 'Non-Compliant' ? 'NCMPT' : item.AAOIFI_status_name == 'Compliant' ? 'CMPT' : item.AAOIFI_status_name == 'Doubtful' ? 'DBTF' : item.AAOIFI_status_name}
      </span>
    </td>                  
    <td style={{textAlign:'center'}}>
      <span className={item.status === '3' ? 'status_active' : 'status_unpublish'}><i className={item.status === '3' ? 'icon-check' : 'icon-cross'}></i>
  </span>
      </td>
      <td style={{ textAlign: 'center' }}>
  {roleName === "MERLIN_SENIOR_ANALYST" || roleName === "MERLIN_ADMIN" ? (
    <Link to={`/report/${item.encryptJSON}`} target="_blank" className='btn_icon_action'>
      <i className='icon-edit' style={{ cursor: "pointer" }}></i>
    </Link>
  ) : roleName === "MERLIN_JUNIOR_ANALYST" && item.status == 1 && item.user_id == userid ? (
    <Link to={`/report/${item.encryptJSON}`} target="_blank" className='btn_icon_action'>
      <i className='icon-edit' style={{ cursor: "pointer" }}></i>
    </Link>
  ) : (
    <Link to={`/report/${item.encryptJSON}`} target="_blank" className='btn_icon_action action_disable'>
      <i className='icon-edit' style={{ cursor: "pointer" }}></i>
    </Link>
  )}
</td>
  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

          {totalPages > 1 && renderPagination()}

          {/* <nav aria-label="..." id='pagination_row'>
                <ul class="pagination">
                    <li class="page-item" style={{marginLeft:10}}>
                      <SelectPicker placement="topStart" data={PagingData} searchable={false} style={{ width: 100, height:32, display:'flex', alignItems:'center', borderRadius: 5, border: '1px solid #A1B9ED'}} />
                    </li>
                    <li class="page-item disabled">
                      <a class="page-link"><i className='fa fa-angle-double-left'></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link"><i className='icon-prev'></i></a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item active" aria-current="page">
                    <a class="page-link" href="#">2</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                    <li class="page-item">
                    <a class="page-link" href="#"><i className='icon-next'></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link"><i className='fa fa-angle-double-right'></i></a>
                    </li>

                    <li class="page-item" style={{marginLeft:10, display:'flex',alignItems:'center', gap:5,}}>
                      <div style={{color:'#878787'}}>go to</div>
                      <input type='text' className='form-control' style={{padding: '0px 10px', color:'#878787',fontSize: 16, borderRadius: 5, border: '1px solid #A1B9ED', width:80, height:32, textAlign:'center'}} />
                    </li>
                </ul>
          </nav> */}
          
        </>    
)}
        </div>
      </div>

      {/* Modal */}
      <div className="modal fade duplicate_form_modal" id="CreateReportModal" tabIndex="-1" aria-labelledby="DuplicateFormModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Igene Biotechnology Inc</h1>
            </div>
            <div className="modal-body">
              <div className='duplicate_form_content'>
                <div className='duplicate_form_row'>
                  <div className='duplicate_form_col'>
                    <label>Report Source</label>
                    <input type='text' className='form-control' placeholder='YYYY' />
                  </div>

                  <div className='duplicate_form_col'>
                    <label>Filling Type</label>
                    <div className='select_drop_picker'>
                      <SelectPicker
                        data={data2}
                        searchable={false}
                        style={{ width: '100%' }}
                        placeholder="Quarter"
                      />
                    </div>
                  </div>
                </div>

                <div className="btn__group">
                  <button type="button" className="btn_cancel" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" className="btn_duplicate btn_add_new_report">Add New Report</button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default StockReport;
