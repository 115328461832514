import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { useParams ,Link, useNavigate } from "react-router-dom";
import iconReportUsers from "../images/iconReportUsers.svg";

function ComplianceProfileReports() {
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [tabName, setTabName] = useState('Compliance Profile Reports');
  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const { company_symbol } = useParams();
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [statusId, setStatusId] = useState("");

  const [copiedText, setCopiedText] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const userid = localStorage.getItem("novauserid");

  //Country List
  useEffect(() => {
    const fetchCountryList = async () => {
      const url = `${apiEndPoint}/get_country_list`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedCountryData = data.data.map((country) => ({
          label: country.country_name,
          value: country.country_code,
        }));

        setCountryData(formattedCountryData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchCountryList();
  }, []);

  // Compliance List
  useEffect(() => {
    const fetchComplianceStatusList = async () => {
      const url = `${apiEndPoint}/get_compliance_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedComplianceStatusData = data.data.map((complaince) => ({
          label: complaince.name,
          value: complaince.id,
        }));

        setComplianceData(formattedComplianceStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchComplianceStatusList();
  }, []);

  //Status List
  useEffect(() => {
    const fetchStatusList = async () => {
      const url = `${apiEndPoint}/get_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedStatusData = data.data.map((status) => ({
          label: status.name,
          value: status.id,
        }));

        setStatusData(formattedStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchStatusList();
  }, []);


  const fetchGlobalReport = async () => {
    setLoading(true);

        try {
      const apiUrl = `${apiEndPoint}/get_company_details`;
      const requestData = {
        data: {
          user_id: userid,
          limit: "20",
          page: currentPage.toString(),
         // q:searchInput || "",
          //country_code: countryCode || "",
          filter_Date:"",
          status: statusId,
          company_symbol:company_symbol
        }
      };
      console.log(JSON.stringify(requestData));
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      const jsonData = await response.json();
      const { status, message, count, data } = jsonData.data;
      if (status === "success") {
        setLoading(false);
        setReportCount(count);
        setReportData(data);
        setCompanyName(data[0].name);
      } else {
        console.error("Error: ", message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchGlobalReport();
  }, [currentPage, searchQuery, countryCode, searchInput,complianceId,statusId]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (page >= startPage + 5) {
      setStartPage(page - 4);
    } else if (page < startPage) {
      setStartPage(page);
    }
  };

  const totalPages = Math.ceil(reportCount / 20);
  const endPage = Math.min(startPage + 4, totalPages);

  const data = ['India', 'United States', 'Australia'].map(
    item => ({ label: item, value: item })
  );

  const data2 = ['Quarter', 'Second Quarter', 'Third Quarter'].map(
    item => ({ label: item, value: item })
  );

  const [copied, setCopied] = useState(false);

  const copyText = (text) => {
  if (text !== copiedText) {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    setCopiedText(text);
    setTimeout(() => {
      setCopiedText(null);
    }, 2000);
  }
};


 //Table Loader 
 
//  useEffect(() => {
//      // Simulate an API call or data loading delay
//      setTimeout(() => setLoading(false), 2000); // Simulate 2 seconds loading time
//    }, []);



const handleSearch = (event) => {
  setSearchInput(event.target.value);
  const inputValue = event.target.value;
  //if (inputValue.length >= 3) {
  setCurrentPage(1); // Reset to the first page for new search
  setSearchQuery(inputValue);
  //}
};


const handleCountryChange = (value) => {
  setCountryCode(value);
};

const handleComplianceChange = (value) => {
  setComplianceId(value);
};

const handleStatusChange = (value) => {
  setStatusId(value);
};


const handleComplianceReport = (encryptJSON) => {
  navigate(`/ComplianceMerlineView/${encryptJSON}`);
};

  return (
    <>
      <Sidebar />
      <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>{companyName}</div>
        </div>
    </div>
      <div className="container-fluid content pb-5">
        <div className='company_table_list'>
        {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
              <tr style={{textAlign:'center'}}>
                  <th>Year</th>
                  <th>Filling</th>
                  <th>RED</th>
                  <th>LUD</th>
                  <th>Ticker</th>
                  <th>Main Ticker</th>
                  <th>ISIN</th>
                  <th>Compliance</th>
                  <th>Publish</th>
                  <th>Doc</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
              {reportData.length > 0 ? (
              reportData.map(item => (
                <tr style={{textAlign:'center'}}>
                <td>{item.year}</td>
                <td>
                {item.quarter === 'FIRST_QUARTER' ? 'Q1' : item.quarter === 'SECOND_QUARTER' ? 'Q2' : item.quarter === 'THIRD_QUARTER' ? 'Q3' : item.quarter}

                </td>
                <td>-</td>
                <td>{item.last_update_time}</td>
                <td style={{textAlign:'center'}}><span className='stock_lable2'>{item.exchange_symbol}</span></td>
                <td style={{textAlign:'center'}}><span className='stock_lable2'>{item.ticker}</span></td>
                <td>{item.isin}</td>
                <td><span className={ item.compliance_status === 'NON_COMPLIANT' ? 'not_halal_tag tag_td' : item.compliance_status === 'COMPLIANT' ? 'halal_tag tag_td' : item.compliance_status === 'QUESTIONABLE' ? 'doughtful_tag tag_td' : 'tag_td' } style={{width:75}}>
                {item.compliance_status === 'NON_COMPLIANT' ? 'NCMPT' : item.compliance_status === 'COMPLIANT' ? 'CMPT' : item.compliance_status === 'QUESTIONABLE' ? 'DBTF' : item.compliance_status}
                  </span></td>                                  
                {/* <td><span className={ item.compliance_status === 'NON_COMPLIANT' ? 'status_active' : item.compliance_status === 'COMPLIANT' ? 'status_active' : item.compliance_status === 'QUESTIONABLE' ? 'status_active' : 'status_unpublish' }><i className={ item.compliance_status === 'NON_COMPLIANT' ? 'icon-check' : item.compliance_status === 'COMPLIANT' ? 'icon-check' : item.compliance_status === 'QUESTIONABLE' ? 'icon-check' : 'icon-cross' }></i></span></td>          */}
                
                <td>
                  <span className={item.publish_un_publish === 'PUBLISH' ? 'status_active' : item.publish_un_publish === 'UN_PUBLISH' ? 'status_unpublish' : '' }>
                    <i className={ item.publish_un_publish === 'PUBLISH' ? 'icon-check' : item.publish_un_publish === 'UN_PUBLISH' ? 'icon-cross' : '' }>
                    </i>
                  </span>
                </td>
                
                <td>-</td>
                {/* <td> <div className='action_row'> <a href='#' className='btn_icon_action' data-bs-toggle="modal" data-bs-target="#DuplicateFormModal"> <i className='icon-duplicate'></i> </a> <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a> </div> </td> */}
              </tr>  
                ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

          <nav aria-label="..." id='pagination_row'>
  <ul className="pagination">
    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
      <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}><i className='icon-prev'></i></a>
    </li>
    {Array.from({ length: Math.min(5, totalPages) }, (_, index) => (
      <li key={index} className={`page-item ${currentPage === startPage + index ? 'active' : ''}`}>
        <a className="page-link" href="#" onClick={() => handlePageChange(startPage + index)}>{startPage + index}</a>
      </li>
    ))}
    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
      <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}><i className='icon-next'></i></a>
    </li>
  </ul>
        </nav>
        </>    
)}
        </div>
      </div>

      {/* Modal */}
      <div className="modal fade duplicate_form_modal" id="CreateReportModal" tabIndex="-1" aria-labelledby="DuplicateFormModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Igene Biotechnology Inc</h1>
            </div>
            <div className="modal-body">
              <div className='duplicate_form_content'>
                <div className='duplicate_form_row'>
                  <div className='duplicate_form_col'>
                    <label>Report Source</label>
                    <input type='text' className='form-control' placeholder='YYYY' />
                  </div>

                  <div className='duplicate_form_col'>
                    <label>Filling Type</label>
                    <div className='select_drop_picker'>
                      <SelectPicker
                        data={data2}
                        searchable={false}
                        style={{ width: '100%' }}
                        placeholder="Quarter"
                      />
                    </div>
                  </div>
                </div>

                <div className="btn__group">
                  <button type="button" className="btn_cancel" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" className="btn_duplicate btn_add_new_report">Add New Report</button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default ComplianceProfileReports;
