import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import IconUpYellow from "../images/IconUpYellow.svg";
import IconUpGreen from "../images/IconUpGreen.svg";

import Sidebar from '../component/Sidebar';
import BunyodUser from "../images/Profile-Bunyod.jpg";
import { Chart } from 'primereact/chart';

function Dashboard() {
  const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const data = {
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            datasets: [
                {
                    label: 'Sales',
                    data: [240, 540, 160, 650, 500, 440, 500], // Adjusted values
                    backgroundColor: [
                        'rgba(43, 55, 121, 1)',
                        'rgba(43, 55, 121, 1)',
                        'rgba(43, 55, 121, 1)',
                        'rgba(245, 185, 34, 1)',
                        'rgba(43, 55, 121, 1)',
                        'rgba(43, 55, 121, 1)',
                        'rgba(43, 55, 121, 1)'
                    ],
                    borderColor: [
                        'rgb(255, 159, 64)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)'
                    ],
                    borderWidth: 0,
                    barThickness: 20,
                    borderRadius: { topLeft: 60, topRight: 60 } // Added top radius
                }
            ]
        };

        const options = {
            plugins: {
              tooltip: {
                  backgroundColor: 'rgba(43, 55, 121, 0.85)', // Change tooltip background color here
                  titleFont: {
                      family: 'Inter',
                      weight: 'bold',
                      size: 14
                  },
                  bodyFont: {
                      family: 'Inter',
                      weight: 'normal',
                      size: 12
                  },
                  displayColors: false, // Optionally hide the color box in the tooltip
                  padding: 10,
                  cornerRadius: 4
              },
                legend: {
                    display: false  
                },
                title: {
                  display: true,
                  text: 'Weekly Average', // Chart name
                  position: 'bottom', // Position at the bottom
                  color: '#878787',
                  font: {
                      family: 'Inter',
                      size: 12,
                      weight: 500
                  },
                  padding: {
                      top: 10,
                      bottom: 0
                  }
              }
            },
            scales: {
              x: {
                ticks: {
                  color: '#767676',
                  font: {
                    family: 'Inter',
                    weight:'bold',
                }
                },
                grid: {
                  drawBorder: false, // Remove x-axis stroke
                  display: false // Optionally, hide grid lines as well
              },
              
            },
                y: {
                    beginAtZero: true,
                    borderWidth:0,
                    ticks: {
                      color: '#767676',
                      
                      
                      font: {
                            family: 'Inter',
                            weight:'bold',
                            size: 12,
                        }
                  },
                  grid: {
                    drawBorder: false, // Remove y-axis stroke
                    color: '#F0F1F1', // Change y-axis grid color
                    borderColor: 'transparent', // Remove left border line
                  },
                  border: {
                      color: 'transparent' // Ensure the borderColor of y-axis is set to transparent
                  }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>Dashboard</div>
        </div>
    </div>
    <div className="container-fluid content pb-5 pt-3">
      <h2 className='title_main_dashboard'>Welcome Back!</h2>
      <div className='dashboard_row mt-3'>
        <div className='dashboard_user_col'>
          <div className='dashboard_user_row'>
            <div className='img_duser'>
              <img src={BunyodUser} />
            </div>

            <div className='details_duser'>
              <h5>Bunyod Rayimov</h5>
              <p>Junior Analyst</p>
              <h6>Last Active : 12 Oct 2023, <span>5:09 AM</span></h6>
            </div>
          </div>
          <div className='dashboard_user_activity'>
            <div className='activity_number_col'>
              <h5>30+</h5>
              <p>Day</p>
            </div>
            <div className='activity_number_col'>
              <h5>210+</h5>
              <p>Week</p>
            </div>
            <div className='activity_number_col'>
              <h5>867+</h5>
              <p>Month</p>
            </div>
          </div>
        </div>

        <div className='dashboard_company_col'>
          <div className='total_companies_row'>
              <p>Total Companies Analysed (Avg)</p>
              <div className='tc_number'>1,024+</div>
          </div>

          <div className='dreport_review_row'>
              <div className='col_dreport_review'>
                <h5>124+</h5>
                <p>Reports <br />Published</p>
              </div>
              <div className='col_dreport_review'>
                <h5>10+</h5>
                <p>Reports <br />In Review</p>
              </div>
          </div>
        </div>

        <div className='dashboard_report_col'>
          <div className='dashboard_report_col_row'>

          <div className='dreport__col'>
            <h5>Reports Analysed</h5>
            <div className='dashboard_report_row'>
                
                <div className='dreport_analylsed'>
                  <p>This Week</p>
                  <h4>20 <span>Reports</span></h4>
                </div>

                <div className='dreport_analylsed'>
                  <p>Last Week</p>
                  <h4>34 <span>Reports</span></h4>
                </div>
            </div>
          </div>

          <div className='daily_avrg_graph'>
            <div className='daily_avrg_graph_row'>
                <div className='graph_daily_areport'>
                  <p>Daily Average Reports</p>
                  <h5>24</h5>
                </div>

                <div className='graph_analyed_week'>
                  <p>+2 reports</p>
                  <h6>Analysed this week</h6>
                </div>
            </div>

            <div className="">
              <Chart type="bar" data={chartData} options={chartOptions} />
            </div>
          </div>

          </div>
        </div>
      </div>

      <div className='dashboard_row mt-3'>
        <div className='dcompany_table'>
          <div className='dcompany_table_title mb-3'>
            <h5>Companies Currently Analysing</h5>
            <p>Total Companies : 15</p>
          </div>

          <div className='company_table_list table_dashboard_company table-responsive' style={{maxHeight:320, overflowY:'auto'}}>
            <table className='table'>
              <thead>
                <tr>
                  <th>Ticker and Company name</th>
                  <th>Country</th>
                  <th>Exch</th>
                  <th style={{textAlign:"center"}}>CPML</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className='company_name_td'>
                      <span className='company_lable'>IGNE</span>
                      Igene Biotechnology Inc
                    </div>
                  </td>
                  <td>United States</td>
                  <td>Other OTC</td>
                  <td style={{ textAlign: "center" }}><span className='uncover_tag tag_td'>Uncv</span></td>                  
                </tr>

                <tr>
                  <td>
                    <div className='company_name_td'>
                      <span className='company_lable'>DTI.AX</span>
                      DTI Group Ltd
                    </div>
                    </td>
                  <td>United States</td>
                  <td>Other OTC</td>
                  <td><span className='not_halal_tag tag_td'>NCMPT</span></td>   
                  
                </tr>

                <tr>
                  <td>
                    <div className='company_name_td'>
                    <span className='company_lable'>BCI.AX</span>
                    BCI Minerals Ltd
                    </div>
                    </td>
                  <td>India</td>
                  <td>NASDAQ</td>
                  <td><span className='doughtful_tag tag_td'>DBTF</span></td> 
                </tr>
                <tr>
                  <td>
                    <div className='company_name_td'>
                      <span className='company_lable'>HZR.AX</span>
                      Hazer Group Ltd
                    </div>
                    </td>
                  <td>Australia</td>
                  <td>Other OTC</td>
                  
                  <td><span className='halal_tag tag_td'>CMPT</span></td> 
                </tr> 
                <tr>
                  <td>
                    <div className='company_name_td'>
                      <span className='company_lable'>EWC.AX</span>
                      Energy World Corporation
                    </div>
                    </td>
                  <td>United States</td>
                  <td>NYSE</td>
                  <td><span className='not_halal_tag tag_td'>NCMPT</span></td> 
                </tr>

                <tr>
                  <td>
                    <div className='company_name_td'>
                    <span className='company_lable'>BCI.AX</span>
                    BCI Minerals Ltd
                    </div>
                    </td>
                  <td>India</td>
                  <td>NASDAQ</td>
                  <td><span className='doughtful_tag tag_td'>DBTF</span></td> 
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>

        <div className='dsystem_statics'>
          <h5>System Statistics</h5>
          <p>This contains all the information related to the Nova system</p>
          <div className='row_dsystem_statics mt-3'>
            <div className='dsystem_statics_col'>
              <h5>Total Tickers</h5>
              <p>Only Main Tickers</p>
              <h4>3 Lacs</h4>
            </div>

            <div className='dsystem_statics_col'>
              <h5>Total Companies</h5>
              <p>Main tickers + Subtickers</p>
              <h4>115 K</h4>
            </div>

            <div className='dsystem_statics_col'>
              <h5>Total Exchanges</h5>
              <p><img src={IconUpYellow} />+12 compared to last month</p>
              <h4>81</h4>
            </div>

            <div className='dsystem_statics_col'>
              <h5>Total Published</h5>
              <p><img src={IconUpGreen} />10% more compared to last month</p>
              <h4>10 K</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Dashboard;
