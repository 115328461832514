import React, { useState, useEffect, useRef } from "react";
import Logo from "../images/logo.svg";
import iconReportUsers from "../images/iconReportUsers.svg";
import IconAssign from "../images/AssignBG.svg";
import IconReject from "../images/RejectBG.svg";
import IconDelete from "../images/deleteBG.svg";
import Sidebar from "../component/Sidebar";
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
// import { SelectPicker, DatePicker } from 'rsuite/DatePicker';
import { SelectPicker, DatePicker, Dropdown, Placeholder, Whisper, Button, Popover, Message, useToaster, Tooltip } from "rsuite";
import "rsuite/DatePicker/styles/index.css";
import "rsuite/SelectPicker/styles/index.css";
import "rsuite/Dropdown/styles/index.css";


import { Link, useNavigate } from "react-router-dom";
import moment from 'moment-timezone';

import Open_Link_Icon from "../images/Open_Link_Icon.svg";


//function TaskManager() {
const TaskManager = (props) => {

  
  const timeZone = 'Asia/Kolkata';
  const [tabName, setTabName] = useState('Task Manager');

  useEffect(() => {
    document.title = tabName;
  }, [tabName]);
  

  

  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const frontEndLink = process.env.REACT_APP_MUSAFFA_LINK;

  const merlinAdmin = process.env.REACT_APP_MERLIN_ADMIN;
  const merlinSeniorAnalyst = process.env.REACT_APP_MERLIN_SENIOR_ANALYST;
  const merlinJuniorAnalyst = process.env.REACT_APP_MERLIN_JUNIOR_ANALYST;

  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;
  const reviewStatus = process.env.REACT_APP_TASK_MANAGER_REVIEW_Status;
  const publishStatus = process.env.REACT_APP_TASK_MANAGER_PUBLISH_Status;

  const [globalLoading, setGlobalLoading] = useState(true); // State to manage loading state
  const [progressLoading, setProgressLoading] = useState(true); // State to manage loading state
  const [reviewLoading, setReviewLoading] = useState(true); // State to manage loading state
  const [publishLoading, setPublishLoading] = useState(true); // State to manage loading state


  const navigate = useNavigate();
  const userid = localStorage.getItem("novauserid");
  const assignedBy = localStorage.getItem("novauserid");
  const firstName = localStorage.getItem("novaFirstname");
  const roleName = localStorage.getItem("novaRolename");

  // In your protected route component
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [juniorAssignData, setJuniorAssignData] = useState([]);
  const [seniorAssignData, setSeniorAssignData] = useState([]);
  const [bothAnalystAssignData, setBothAnalystAssignData] = useState([]);

  const [countryData, setCountryData] = useState([]);


  const [selectedUser, setSelectedUser] = useState(null); // Track selected user
  const [selectedUser1, setSelectedUser1] = useState(null); // Track selected user

  //Gloabal Report
  const [reportdata, setReportData] = useState(null);
  const [reportCount, setReportCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  //Progress Report
  const [progressdata, setProgressData] = useState(null);
  const [progressCount, setProgressCount] = useState(null);
  const [currentPageProgress, setCurrentPageProgress] = useState(1);
  const [startPageProgress, setStartPageProgress] = useState(1);
  const [searchQueryProgress, setSearchQueryProgress] = useState("");

  //Reviewing Report
  const [reviewdata, setReviewData] = useState(null);
  const [reviewCount, setReviewCount] = useState(null);
  const [currentPageReview, setCurrentPageReview] = useState(1);
  const [startPageReview, setStartPageReview] = useState(1);
  const [searchQueryReview, setSearchQueryReview] = useState("");

  //Publish Report
  const [publishdata, setPublishData] = useState(null);
  const [publishCount, setPublishCount] = useState(null);
  const [currentPagePublish, setCurrentPagePublish] = useState(1);
  const [startPagePublish, setStartPagePublish] = useState(1);
  const [searchQueryPublish, setSearchQueryPublish] = useState("");


  const [filterDate, setFilterDate] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [analystId, setAnalystId] = useState("");
  

  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info

  // Popup For Report : Assign To Junior 
  const [openReportPopover, setOpenReportPopover] = useState(null);
  const handlePopoverOpen = (id) => {
    setOpenReportPopover(openReportPopover == id ? null : id);
  };



// Popup For Progress : Delete Report
const [openDeletePopover, setOpenDeletePopover] = useState(null);
const handleDeletePopoverOpen = (id) => {
  setOpenDeletePopover(openDeletePopover == id ? null : id);
};

// Popup For Review : Assign To Senior
const [openReviewPopover, setOpenReviewPopover] = useState(null);
const handleReviewPopoverOpen = (id) => {
  setOpenReviewPopover(openReviewPopover == id ? null : id);
};


// Popup For Reject : Reject Send To Progress
const [openRejectPopover, setOpenRejectPopover] = useState(null);
const handleRejectPopoverOpen = (id) => {
  setOpenRejectPopover(openRejectPopover == id ? null : id);
};


  //const [totalPagesProgress, setTotalPagesProgress] = useState(1);

  //Global Report

  const fetchGlobalReport = async () => {

    setGlobalLoading(true);
    try {
      const zonedDate = moment(filterDate).tz(timeZone).format();
    

      const apiUrl = `${apiEndPoint}/get_global_report`;
      const requestData = {
        data: {
          user_id: userid,
          limit: "20",
          page: currentPage.toString(),
          sort_order: "desc",
          q: searchQuery || "",
          country_code: countryCode || "",
          filter_Date: zonedDate || ""
        }
      };

      //console.log(JSON.stringify(requestData));

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });
      
      const jsonData = await response.json();
      if (jsonData.data.status === "success") {
        setGlobalLoading(false);
        setReportCount(jsonData.data.count);
        setReportData(jsonData.data.data);
      } else {
        setGlobalLoading(false);
        setErrorMessage(jsonData.data.message);
        setMessageType('error');
        return false;
      }
    } catch (error) {
      setGlobalLoading(false);
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };
  
  useEffect(() => {
    fetchGlobalReport();
     // Set up interval to call the function every minute (60000 ms)
     const intervalId = setInterval(fetchGlobalReport, 60000);
     // Clear the interval when the component unmounts or dependencies change
     return () => clearInterval(intervalId);

    
  }, [currentPage, searchQuery,countryCode,filterDate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Adjust start page dynamically
    if (page >= startPage + 5) {
      setStartPage(page - 4);
    } else if (page < startPage) {
      setStartPage(page);
    }
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(reportCount / 20); // Assuming 20 items per page
  const endPage = Math.min(startPage + 4, totalPages);

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
    const inputValue = event.target.value;
    //if (inputValue.length >= 3) {
    setCurrentPage(1); // Reset to the first page for new search
    setSearchQuery(inputValue);
    //}
  };

  //In Progress
  const fetchProgressReport = async () => {
    setProgressLoading(true);
    try {
      const zonedDate = moment(filterDate).tz(timeZone).format();
      const userid = localStorage.getItem("novauserid");
      const request = {
        data: {
          user_id: userid,
          status: progressStatus, // 1
          limit: "20",
          page: currentPageProgress,
          sort_order: "desc",
          q: searchQueryProgress || "",
          country_code: countryCode || "",
          filter_Date: zonedDate || "",
          analyst_Id: analystId || ""
        }
      };
  
      const apiUrl = `${apiEndPoint}/get_status_report`;
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
  
      const jsonData = await response.json();
      const data = jsonData.data; // Flatten the response
      setProgressLoading(false);
      setProgressCount(data.count);
      setProgressData(data.data);
    } catch (error) {
      setProgressLoading(false);      	
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };
  

  useEffect(() => {
    fetchProgressReport();
    
    // Set up interval to call the function every minute (60000 ms)
    const intervalId = setInterval(fetchProgressReport, 120000);
    // Clear the interval when the component unmounts or dependencies change
    return () => clearInterval(intervalId);

  }, [currentPageProgress, searchQueryProgress,countryCode,analystId,filterDate]);

  const handleProgressPageChange = (page) => {
    setCurrentPageProgress(page);
    // Adjust start page dynamically
    if (page >= startPage + 5) {
      setStartPageProgress(page - 4);
    } else if (page < startPage) {
      setStartPageProgress(page);
    }
  };

  // Calculate total number of pages : Progress
  const totalPages1 = Math.ceil(progressCount / 20); // Assuming 20 items per page
  const totalPagesProgress = Math.min(startPageProgress + 4, totalPages1);

  const handleProgressSearch = (event) => {
    setSearchProgressInput(event.target.value);
    const inputValue = event.target.value;
    //if (inputValue.length >= 3) {
    setCurrentPageProgress(1); // Reset to the first page for new search
    setSearchQueryProgress(inputValue);
    //}
  };

  // Reviewing
  const fetchReviewingReport = async () => {
    setReviewLoading(true);
    try {
      const zonedDate = moment(filterDate).tz(timeZone).format();
      const userid = localStorage.getItem("novauserid");
      const request = {
        data: {
          user_id: userid,
          status: reviewStatus, // 2
          limit: "20",
          page: currentPageReview,
          sort_order: "desc",
          q: searchQueryReview || "",
          country_code: countryCode || "",
          filter_Date: zonedDate || "",
          analyst_Id: analystId || ""
        }
      };
  
      const apiUrl = `${apiEndPoint}/get_status_report`;
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
  
      const jsonData = await response.json();
      const data = jsonData.data; // Flatten the response

      setReviewCount(data.count);
      setReviewData(data.data);
      setReviewLoading(false);
    } catch (error) {
      setReviewLoading(false);      	
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };

  useEffect(() => {
    fetchReviewingReport();
    // Set up interval to call the function every minute (60000 ms)
    const intervalId = setInterval(fetchReviewingReport, 120000);
    // Clear the interval when the component unmounts or dependencies change
    return () => clearInterval(intervalId);

  }, [currentPageReview, searchQueryReview,countryCode,analystId,filterDate]);

  const handleReviewingPageChange = (page) => {
    setCurrentPageReview(page);
    // Adjust start page dynamically
    if (page >= startPage + 5) {
      setStartPageReview(page - 4);
    } else if (page < startPage) {
      setStartPageReview(page);
    }
  };

  // Calculate total number of pages : Progress
  const totalReviewPages = Math.ceil(reviewCount / 20); // Assuming 20 items per page
  const totalPagesReview = Math.min(startPageReview + 4, totalReviewPages);

  const handleReviewingSearch = (event) => {
    setSearchReviewInput(event.target.value);
    const inputValue = event.target.value;
    //if (inputValue.length >= 3) {
    setCurrentPageReview(1); // Reset to the first page for new search
    setSearchQueryReview(inputValue);
    //}
  };

  //Published
  const fetchPublishedReport = async () => {
    setPublishLoading(true);
    try {
      const zonedDate = moment(filterDate).tz(timeZone).format();
      const userid = localStorage.getItem("novauserid");
      const request = {
        data: {
          user_id: userid,
          status: publishStatus, // 2
          limit: "20",
          page: currentPagePublish,
          sort_order: "desc",
          q: searchQueryPublish || "",
          country_code: countryCode || "",
          filter_Date: zonedDate || "",
          analyst_Id: analystId || ""
        }
      };
  
      const apiUrl = `${apiEndPoint}/get_status_report`;
      console.log(JSON.stringify(request));
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
  
      const jsonData = await response.json();
      const data = jsonData.data; // Flatten the response
      setPublishLoading(false);
      setPublishCount(data.count);
      setPublishData(data.data);
      console.log(data.data);
    } catch (error) {
      setPublishLoading(false);
      	
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };

  useEffect(() => {
    fetchPublishedReport();
     // Set up interval to call the function every minute (60000 ms)
     const intervalId = setInterval(fetchPublishedReport, 120000);
     // Clear the interval when the component unmounts or dependencies change
     return () => clearInterval(intervalId);

  }, [currentPagePublish, searchQueryPublish,countryCode,analystId,filterDate]);

  const handlePublishPageChange = (page) => {
    setCurrentPagePublish(page);
    // Adjust start page dynamically
    if (page >= startPage + 5) {
      setStartPagePublish(page - 4);
    } else if (page < startPage) {
      setStartPagePublish(page);
    }
  };

  // Calculate total number of pages : Progress
  const totalPublishPages = Math.ceil(publishCount / 20); // Assuming 20 items per page
  const totalPagesPublish = Math.min(startPagePublish + 4, totalPublishPages);

  const handlePublishSearch = (event) => {
    setSearchPublishInput(event.target.value);
    const inputValue = event.target.value;
    //if (inputValue.length >= 3) {
    setCurrentPagePublish(1); // Reset to the first page for new search
    setSearchQueryPublish(inputValue);
    //}
  };


  // const handleLinkClick = async (itemId, userId) => {
  //   const url = "https://beta.infomanav.in/keep/finnhub_api/task_manager/nv_user_task.php";
  //   const payload = {
  //     user_id: userId, //localStorage.getItem('novauserid'), // Replace this with the actual user_id if dynamic
  //     task_id: itemId,
  //     status: progressStatus,
  //   };

  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const data = await response.json();

  //     if (data.status === "success") {
  //       return true; // Indicate success
  //     } else {
  //       console.error("API Error:", data.message);
  //       return false; // Indicate failure
  //     }
  //   } catch (error) {
  //     console.error("API Error:", error);
  //     return false; // Indicate failure
  //   }
  // };


  const updateReport = async (assignedId,company_symbol,date,quarter,year,isStatus) => {
    const url = `${apiEndPoint}/update_tasks`;
    const payload = {
      data: {
        user_id: assignedId, // Replace this with the actual user_id if dynamic
        company_symbol: company_symbol, // Assuming itemId represents company_symbol
        date: date, // Replace this with the actual date if dynamic
        quarter: quarter, // Replace this with the actual quarter if dynamic
        year: year, // Replace this with the actual year if dynamic
        status: progressStatus
      }
    };

    // console.log(JSON.stringify(payload));
    // return false;

    
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      const data = responseData.data; // Flatten the response
      if (data.status === "success") {
        //window.open(`/report/ancasdasdas`, '_blank');
          //navigate(`/report/${data.data.encryptJSON}`);
        //
        if(isStatus == 1)
        {
          navigate(`/report/${data.data.encryptJSON}`);
        }
        else{}
        return true; // Indicate success
      } else {
        setErrorMessage(data.message || 'Failed');
        setMessageType('error');
        fetchGlobalReport();
        fetchProgressReport();
        return false;
      }
    } catch (error) {
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };


  const handleLinkClick = async (company_symbol,date,quarter,year,isStatus) => {
    const url = `${apiEndPoint}/update_tasks`;
    const payload = {
      data: {
        user_id: userid, // Replace this with the actual user_id if dynamic
        company_symbol: company_symbol, // Assuming itemId represents company_symbol
        date: date, // Replace this with the actual date if dynamic
        quarter: quarter, // Replace this with the actual quarter if dynamic
        year: year, // Replace this with the actual year if dynamic
        status: progressStatus,
        old_status:2 // This is added on 24-07 for check its already reject or not.
      }
    };
    

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const responseData = await response.json();
      const data = responseData.data; // Flatten the response
      if (data.status === "success") {
        //window.open(`/report/ancasdasdas`, '_blank');
        if(isStatus)
        {
          navigate(`/report/${data.data.encryptJSON}`);
        }
        //
        return true; // Indicate success
      } else {        	
        setErrorMessage(data.message);
        setMessageType('error');
        fetchProgressReport();
        fetchReviewingReport();
        return false;
      }
    } catch (error) {
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };
  
                                //itemId, userId, reviewId,company_symbol,date,quarter,year
  const handleLinkClick1 = async (taskId, userId, seniorUserid,company_symbol,date,quarter,year) => {
    const url = `${apiEndPoint}/update_tasks`;
    const payload1 = {
      data: {
        user_id: userId, // Replace this with the actual user_id if dynamic
        company_symbol: company_symbol, // Assuming itemId represents company_symbol
        date: date, // Replace this with the actual date if dynamic
        quarter: quarter, // Replace this with the actual quarter if dynamic
        year: year, // Replace this with the actual year if dynamic
        status: reviewStatus,
        reviewed_by:seniorUserid
      }
    };


  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload1),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const responseData = await response.json();
      const data = responseData.data; // Flatten the response
      if (data.status === "success") {
        return true; // Indicate success
      } else {
        setErrorMessage(data.message);
        setMessageType('error');
        return false;
      }
    } catch (error) {
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };


  const handleProcessReport = async (assignedId,company_symbol,date,quarter,year,isStatus) => {
    const success = await updateReport(assignedId,company_symbol,date,quarter,year,isStatus);
    if (success) {
      fetchProgressReport(); // Call fetchData if handleLinkClick was successful
      fetchGlobalReport();
    }
  };

  const handleItemClick = async (company_symbol,date,quarter,year,isStatus) => {
    const success = await handleLinkClick(company_symbol,date,quarter,year,isStatus);
    if (success) {
      fetchProgressReport(); // Call fetchData if handleLinkClick was successful
      fetchGlobalReport();
    }
  };

  const handleItemClick1 = async (taskId, userId, seniorUserid,company_symbol,date,quarter,year) => {
    
    const success = await handleLinkClick1(taskId, userId, seniorUserid,company_symbol,date,quarter,year);
    if (success) {
      fetchReviewingReport();
    }
  };


  // DATEPICKER
  const [date, setDate] = useState(new Date());

  // handle onChange event of the dropdown
  const handleChange = (value) => {
    setDate(value);
  };

  // Check if isLoggedIn is set to true or not
  if (!isLoggedIn) {
    navigate("/");
  }

  // START ASSIGN TOOLTIP
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);
  const tooltipRef1 = useRef(null);


  const [visibleTooltipId1, setVisibleTooltipId1] = useState(null);
  const [visibleTooltipId2, setVisibleTooltipId2] = useState(null);
  const [visibleTooltipId3, setVisibleTooltipId3] = useState(null);


  const handleButtonDeleteClick = (id) => {
    setOpenDeletePopover(null);
  };

  const handleButtonClick1 = (id) => {
    if (visibleTooltipId1 === id) {
      setVisibleTooltipId1(null);
    } else {
      setVisibleTooltipId1(id);
    }
  };

  const handleButtonClick2 = (id) => {
    if (visibleTooltipId2 === id) {
      setVisibleTooltipId2(null);
    } else {
      setVisibleTooltipId2(id);
    }
  };

  const handleClickOutside = (event) => {
    const selectPickerPopup = document.querySelector(
      ".rs-picker-select-menu.rs-picker-popup"
    );
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      (!selectPickerPopup || !selectPickerPopup.contains(event.target))
    ) {
      setIsTooltipVisible(false);
    }
  };

  useEffect(() => {
    if (isTooltipVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTooltipVisible]);

  // const AssignData = [
  //   {
  //     label: 'Bunyod Rayimov',
  //     value: 'Bunyod',
  //     role: 'Junior Analyst',
  //     avatar: 'https://via.placeholder.com/30',
  //   },
  //   {
  //     label: 'Doniyor Akhmadjonov',
  //     value: 'Doniyor',
  //     role: 'Senior Analyst',
  //     avatar: 'https://via.placeholder.com/30',
  //   },
  //   {
  //     label: 'Yakhyo Sharofov',
  //     value: 'Yakhyo',
  //     role: 'Junior Analyst',
  //     avatar: 'https://via.placeholder.com/30',
  //   },
  //   {
  //     label: 'Azizbek Ortikov',
  //     value: 'Azizbek',
  //     role: 'Junior Analyst',
  //     avatar: 'https://via.placeholder.com/30',
  //   },
  //   {
  //     label: 'Bunyod Rayimov',
  //     value: 'Bunyod',
  //     role: 'Junior Analyst',
  //     avatar: 'https://via.placeholder.com/30',
  //   },
  //   // Add more data as needed
  // ];

  const handleOpen = () => {
    // Wait for the dropdown to be rendered
    setTimeout(() => {
      const popupDiv = document.querySelector(
        ".rs-picker-select-menu.rs-picker-popup"
      );
      if (popupDiv) {
        popupDiv.classList.add("selectPickerTMMain");
      }
    }, 0);
  };

  const handleOpen1 = () => {
    // Wait for the dropdown to be rendered
    setTimeout(() => {
      const popupDiv = document.querySelector(
        ".rs-picker-select-menu.rs-picker-popup"
      );
      if (popupDiv) {
        popupDiv.classList.add("selectPickerTMMain");
      }
    }, 0);
  };

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiEndPoint}/get_user_lists`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {
                user_id: userid,
              },
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        if (data.status === "success") {
          const formattedJuniorData = data.data.junior_data.map((user) => ({
            label: user.user_name,
            value: user.user_name,
            role: user.role_name.replace("MERLIN_", "").replace("_", " "),
            avatar: user.profile_img || "https://via.placeholder.com/30",
            userId: user.user_id,
          }));

          const formattedSeniorData = data.data.senior_data.map((user) => ({
            label: user.user_name,
            value: user.user_name,
            role: user.role_name.replace("MERLIN_", "").replace("_", " "),
            avatar: user.profile_img || "https://via.placeholder.com/30",
            userId: user.user_id,
          }));

          const formattedBothData = data.data.senior_junior_data.map(
            (user) => ({
              label: user.user_name,
              value: user.user_id,
              userId: user.user_id,
            })
          );

          setJuniorAssignData(formattedJuniorData);
          setSeniorAssignData(formattedSeniorData);
          setBothAnalystAssignData(formattedBothData);
        }
      } catch (error) {
        setErrorMessage(error.message);
        setMessageType('error');
        return false;
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    const fetchCountryList = async () => {
      const url = `${apiEndPoint}/get_country_list`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedCountryData = data.data.map((country) => ({
          label: country.country_name,
          value: country.country_code,
        }));

        setCountryData(formattedCountryData);
  
      } catch (error) {
        setErrorMessage(error.message);
        setMessageType('error');
        return false;
      }
    };
  
    fetchCountryList();
  }, []);
  


  const handleAssign = (assignedId,company_symbol,date,quarter,year) => {
    if (assignedId) {
      console.log("Selected Junior Id:", assignedId);

      //0 : indicated dont send the data on screening page
      handleProcessReport(assignedId,company_symbol,date,quarter,year,0);
      setSelectedUser(null);
      setOpenReportPopover(null);
    } else {
      console.log("No user selected"+ assignedId);
    }
  };

  const handleAssign1 = (seniorUserid, userId, taskId,company_symbol,date,quarter,year) => {
    if (seniorUserid) {
      console.log("Selected seniorUserid ID:", seniorUserid);
      // Perform the assignment logic here
      handleItemClick1(taskId, userId, seniorUserid,company_symbol,date,quarter,year);
      setOpenReviewPopover(null);
      setSelectedUser1(null);
    } else {
      console.log("No user selected");
    }
  };

  // END ASSIGN TOOLTIP

  const modalRef = useRef(null);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
  };

  const handleDeleteConfirm = (deleteId) => {
    deleteTask(deleteId);
  };

  const deleteTask = async (deleteId) => {
    const url = `${apiEndPoint}/deleteTask`;
  
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ data: { user_id: userid, id: deleteId } }),
    };
  
    try {
      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      const data = responseData.data; // Flatten the response
      if(data.status === 'success')
        {
          console.log("Task deleted successfully:", data);
          setDeleteId(null);// Reset the deleteId after deletion
          fetchProgressReport();
          fetchGlobalReport();
        }
        else if(data.status === 'error'){
          setErrorMessage(data.message);
          setMessageType('error');
          fetchProgressReport();
          fetchGlobalReport();
          return false;
        }
    } catch (error) {
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };
  
  //Reject 

  const [reject, setRejectId] = useState([]);

  const handleRejectClick = (id) => {
    setRejectId(id);
  };

  const handleRejectConfirm = async (item) => {
    
    const success = await handleLinkClick(item.company_symbol,item.date,item.quarter,item.year,0);
    if (success) {
      fetchProgressReport(); // Call fetchData if handleLinkClick was successful
      fetchReviewingReport();
    }
  };


  const [searchInput, setSearchInput] = useState("");
  const [searchProgressInput, setSearchProgressInput] = useState("");
  const [searchReviewInput, setSearchReviewInput] = useState("");
  const [searchPublishInput, setSearchPublishInput] = useState("");

  const clearSearchInput = () => {
    setSearchInput("");
    setCurrentPage(1); // Reset to the first page for new search
    setSearchQuery("");
  };

  const clearProgressSearchInput = () => {
    setSearchProgressInput("");
    setCurrentPageProgress(1); // Reset to the first page for new search
    setSearchQueryProgress("");
  };

  const clearReviewSearchInput = () => {
    setVisibleTooltipId1(null);
    setSearchReviewInput("");
    setCurrentPageReview(1); // Reset to the first page for new search
    setSearchQueryReview("");
  };

  const clearPublishSearchInput = () => {
    setSearchPublishInput("");
    setCurrentPagePublish(1); // Reset to the first page for new search
    setSearchQueryPublish("");
  };

  const handleFilterDateChange = (value) => {  
    setCurrentPage(1);
    setCurrentPageProgress(1);
    setCurrentPageReview(1);
    setCurrentPagePublish(1);
    setStartPage(1);
    setStartPageProgress(1);
    setStartPageReview(1);
    setStartPagePublish(1);

  
    setFilterDate(value);
  };

  const handleCountryChange = (value) => {
    setCurrentPage(1);
    setCurrentPageProgress(1)
    setCurrentPageReview(1)
    setCurrentPagePublish(1)
    setStartPage(1);
    setStartPageProgress(1);
    setStartPageReview(1);
    setStartPagePublish(1);
    setCountryCode(value);
  };

  const handleAnalystChange = (value) => {
    
    setCurrentPageProgress(1)
    setCurrentPageReview(1)
    setCurrentPagePublish(1)

    setStartPageProgress(1);
    setStartPageReview(1);
    setStartPagePublish(1);

    setAnalystId(value);
  };


  const handleClearFilters = () => {
    setOpenReportPopover(null);
    setFilterDate(null);
    setCountryCode(''); 
    setAnalystId('');
  };


  const handleComplianceReport = (encryptJSON) => {
    navigate(`/ComplianceView/${encryptJSON}`);
  };



  const scrollContainerRef = useRef(null);
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const handleScroll = () => {
    
    setOpenReportPopover(null);
  };



  const hideMessage = () => {
    setMessageType(null);
  };

  // Error Message

  const renderMessage = () => {
    if (messageType) {
      setTimeout(hideMessage, 5000);
    };

    switch (messageType) {
      case 'success':
        return (
          <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_success'>
                <div className='icon_error_msg_inner Icon_success_inner'>
                  <img src={IconCheckError} alt="Success Icon" />
                  <div className="progress-circle progress_circle_success"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_success_text'>
                <h5>Success</h5>
                <p>You have logged in successfully!</p>
              </div>
            </div>
          </Message>
        );
      case 'error':
        return (
          <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_error'>
                <div className='icon_error_msg_inner Icon_error_inner'>
                  <img src={IconError} alt="Error Icon" />
                  <div className="progress-circle progress_circle_error"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_error_text'>
                <h5>Error</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
        case 'info':
        return (
          <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_info'>
                <div className='icon_error_msg_inner Icon_info_inner'>
                  <img src={IconInfo} alt="Info Icon" />
                  <div className="progress-circle progress_circle_info"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_info_text'>
                <h5>Info</h5>
                <p>No reports found for the selected priority</p>
              </div>
            </div>
          </Message>
        );
        default:
          return null;
    }
  };
  



  const renderPagination = () => {
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
        </li>
      );
    }
  
    return (
      <nav aria-label="..." id='pagination_row'>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
              <i className='fa fa-angle-double-left'></i>
            </a>
          </li>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
              <i className='icon-prev'></i>
            </a>
          </li>
          {pages}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
              <i className='icon-next'></i>
            </a>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
              <i className='fa fa-angle-double-right'></i>
            </a>
          </li>
        </ul>
      </nav>
    );
  };



  



 
  return (
    <>
      <Sidebar />
     
      <div id="header">
      {renderMessage()}
        <div className="logo">
          <div className="logo_icon">
            <img src={Logo} />
          </div>
          <div className="brand_name">Task Manager</div>
        </div>

        <div className="header_table_filter">
          <ul className="header_flag_list">
            <li className="orange_flag">
              <i className="icon-flag"></i>
            </li>
            <li className="red_flag">
              <i className="icon-flag"></i>
            </li>
            <li className="green_flag">
              <i className="icon-flag"></i>
            </li>
          </ul>

          <div className="datepick calendar_col">
            <DatePicker
              oneTap
              style={{ width: 160 }}
              placeholder="Choose Date"
              format="dd-MM-yyyy"
              value={filterDate}
              onChange={handleFilterDateChange}
            />
          </div>

          <div className="dropdown_style filter_by_country">
            <SelectPicker
              data={countryData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Country"
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>

          <div className="dropdown_style">
            <SelectPicker
              data={bothAnalystAssignData}
              searchable={false}
              style={{width:200}}
              placeholder="Filter by Analyst Name"
              onChange={handleAnalystChange}
              value={analystId}
            />
          </div>

          <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
      </div>
      <div className="container-fluid content pb-5">
        <div className="task_row">

          <div className="task_col task_report_col" >
            <div className="task_col_header">
              <div className="task_title">
                {/* <h4>Reports <span>({reportdata.count})</span></h4> */}
                <h4>
                  Reports {reportCount !== null && <span>({reportCount})</span>}
                </h4>
              </div>
              <input
                type="text"
                id="searchInput"
                placeholder="Search"
                value={searchInput}
                onChange={handleSearch}
                autocomplete="off"
              />
              <button
                className="btn_task_search"
                onClick={clearSearchInput}
              ></button>
            </div>

            {globalLoading && ( <Placeholder.Grid rows={20} columns={2} active style={{paddingTop:12}} /> )}

{/* Table */}
{!globalLoading && (
<>
            <div className="task_col_inner" ref={scrollContainerRef} >
              {reportdata && reportdata.length === 0 ? (
                <div className="clsNoData">No data available</div>
              ) : (
                reportdata &&
                reportdata.map((item, index) => (
                  <div key={index} className="project_task_row project_task_report" >
                    <div className="project_task_head_row">
                      <ul className="ticker_locate_row">
                        <li className="ticker_task">{item.company_symbol}</li>
                        <li className="ticker_locate">
                          {item.exchange_symbol}
                        </li>
                        <li className="seprate" style={{width:1, height:13, alignSelf:'center', marginLeft:5, marginRight:5, backgroundColor:'#7D7D7D', }} />
                            <li className="ticker_country">
                            {item.country}
                            </li>
                      </ul>
                      {/* <TooltipProvider> */}
                        <ul className="task_nav" style={{}}>
                          <li className="task_number">Q{item.quarter}-{item.year}</li>
                          {(roleName === merlinAdmin || roleName === merlinSeniorAnalyst) && (
     <li>
      <div>
      <Whisper placement="right" trigger="click" open={openReportPopover == item.id}
          //onOpen={() => handlePopoverOpen(item.id)}
          onClose={() => setOpenReportPopover(null)}
          speaker={
            <Popover className="tooltip_form Rsuite_popover" preventOverflow>
              <h5>{item.name+' '+ item.exchange_symbol}</h5>
              <div className="tooltip_form_inner">
                <label>Select Analyst</label>
                <div>
                  <SelectPicker
                    {...props}
                    // onSelect={handleSelectPopover1}
                    data={juniorAssignData}
                    searchable={false}
                    placeholder="Analyst Name"
                    // style={{ width: 300 }}
                    menuClassName="rs-picker-select-menu rs-picker-popup selectPickerTMMain"
                    renderMenuItem={(label, item) => (
                      <div>
                        <img
                          src={item.avatar}
                          alt={item.label}
                          style={{ width: 44, height: 44, marginRight: 10 }}
                        />
                        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                          <div className="drop_title">{item.label}</div>
                          <div className="drop_designation">{item.role}</div>
                        </div>
                      </div>
                    )}
                    renderValue={(value, item) => item.label}
                    onChange={(value, item) => { if (value === null) { setSelectedUser(null); } }}
                    onSelect={(value, item) => setSelectedUser(item.userId) }
                  />
                </div>
                <div className='btn__group mt-5'>
                <a href="#" className="btn__cancel" onClick={() => setOpenReportPopover(null)}> Cancel </a>
                <a href="#" className="btn__assign" onClick={() => handleAssign(selectedUser,item.company_symbol,item.date,item.quarter,item.year) } >
                 Assign
                 </a>
                </div>
              </div>
            </Popover>
          }
        >
          {/* <Button onClick={handleOpenPopover1}>Open Popover1</Button> */}
          <Button className="btn_assign" data-tooltip-id="my-tooltip" onClick={() => handlePopoverOpen(item.id)}>
         
          <div>
        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>You Can Assign Junior Analyst From Here</Tooltip>} >
        <img src={IconAssign}/>
          </Whisper>
        </div>

         

       </Button>
        </Whisper>
     </div>
     </li>
  )}
                        </ul>
                      {/* </TooltipProvider> */}
                    </div>

                      {/* <h5>                                                                                       
                        <Link style={{ textDecoration: "none", color: "black" }} onClick={() => handleProcessReport(userid,item.company_symbol,item.date,item.quarter,item.year,1)} >
                          <h5>{item.name}</h5>
                        </Link>
                      </h5> */}

                          <div className="company_with_logo">
                            <div className="clogo">
                              <img src={item.logo} />
                            </div>
                              <Link style={{ textDecoration: "none", color: "black", width: 'calc(100% - 24px)', whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', }} onClick={() => handleProcessReport(userid,item.company_symbol,item.date,item.quarter,item.year,1)} >
                                <h5 style={{marginTop:0, overflow: 'inherit',}}>{item.name}</h5>
                              </Link>
                           </div>

                    
                    <div className="task_date_row">
                      <p>
                        LRD : <span className="task_date">{item.date}</span>
                      </p>

                      <p>
                        LAD : <span className="task_date">{item.lad_date}</span>
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>

            {reportdata && reportdata.length > 0 && (
              <nav aria-label="..." className="clsPagination">
                <ul className="pagination">
                  <li className={`page-item ${ currentPage === 1 ? "disabled" : "" }`} >
                    <a className="page-link" onClick={() => handlePageChange(currentPage - 1)} style={{ cursor: "pointer" }} >
                      <i className="icon-prev"></i>
                    </a>
                  </li>
                  {[...Array(endPage - startPage + 1).keys()].map((page) => (
                    <li key={startPage + page} className={`page-item ${ currentPage === startPage + page ? "active" : "" }`} >
                      <a className="page-link" onClick={() => handlePageChange(startPage + page)} style={{ cursor: "pointer" }} > {startPage + page} </a>
                    </li>
                  ))}
                  <li className={`page-item ${ currentPage === totalPages ? "disabled" : "" }`} >
                    <a className="page-link" onClick={() => handlePageChange(currentPage + 1)} style={{ cursor: "pointer" }} >
                      <i className="icon-next"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            )}

</>
)}
          </div>

          <div className="task_col task_progress_col">
            <div className="task_col_header">
              <div className="task_title">
                <h4>
                  In Progress <span>({progressCount})</span>
                </h4>
              </div>

              <input
                type="text"
                id="searchInputprogress"
                placeholder="Search"
                value={searchProgressInput}
                onChange={handleProgressSearch}
                autocomplete="off"
              />
              <button
                className="btn_task_search"
                onClick={clearProgressSearchInput}
              ></button>
            </div>
            {progressLoading && ( <Placeholder.Grid rows={20} columns={2} active style={{paddingTop:12}} /> )}

{/* Table */}
{!progressLoading && (
<>
            <div className="task_col_inner">
              {progressdata && progressdata.length === 0 ? (
                <div className="clsNoData">No data available</div>
              ) : (
                progressdata &&
                progressdata.map((item, index) => (
                  // <div key={index} className="project_task_row project_task_progress">
                
                 <div key={index} className={`project_task_row project_task_progress ${item.is_report_accessible == 1 ? "" : "disable_blur" }`} >
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover"  speaker={<Tooltip className={`${item.is_report_accessible == 1 ? "clsTooltipDisable" : "clsTooltipEnable" }`}>{item.accessible_msg}</Tooltip>}> 
                    <div>
                    <div className="project_task_head_row">
                      <ul className="ticker_locate_row">
                        <li className="ticker_task">{item.company_symbol}</li>
                        <li className="ticker_locate">
                          {item.exchange_symbol}
                        </li>
                        <li className="seprate" style={{width:1, height:13, alignSelf:'center', marginLeft:5, marginRight:5, backgroundColor:'#7D7D7D', }} />
                            <li className="ticker_country">
                              {item.country}
                            </li>
                      </ul>

                      {/* <ul className="task_nav">
                      
                        {item.CanDelete && (
                          <li className="">
                            <Dropdown title={<i className="icon-dotmenu"></i>}>
                              <Dropdown.Item
                                className="task_delete_link"
                                data-bs-toggle="modal"
                                data-bs-target="#ReportDeleteModal"
                                onClick={() => handleDeleteClick(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown>
                          </li>
                        )}
                      </ul> */}
                   
                      <ul className="task_nav">
                      {/* <li className=""> <i className="icon-flag red_flag"></i></li> */}
                        {item.CanDelete && (
                         

                            <Whisper
          placement="right"
          trigger="click"
          open={openDeletePopover == item.id}
          //onOpen={() => handlePopoverOpen(item.id)}
          onClose={() => setOpenDeletePopover(null)}
          speaker={
            <Popover className="tooltip_form Rsuite_popover" preventOverflow>
              <div className="tooltip_form_inner text-center">
                                    <div style={{fontSize:20, color: "#141B3C",fontWeight: 600, lineHeight:1.3}}>
                                        Are you sure you want to <br />
                                        delete this report ?
                                      </div>
                                      <p style={{color: '#696969',fontSize: 14,fontWeight: 500, marginTop: 15,}}>
                                        The report of {item.name} will be deleted from your task manager but will remain in the database
                                      </p>

                                    <div className='btn__group mt-5'>
                                      <a href="#" className='btn__cancel' onClick={() => setOpenDeletePopover(null)}>Cancel</a>
                                      <a href="#" className='btn__assign'  onClick={() => handleDeleteConfirm(item.id)}>Delete</a>
                                      
                                    </div>
                                </div>
            </Popover>
          }
        >
          {/* <Button onClick={handleOpenPopover1}>Open Popover1</Button> */}
          <Button className="btn_assign" data-tooltip-id="my-tooltip" onClick={() => handleDeletePopoverOpen(item.id)}>
         

         <div>
        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>You Can Delete Report From Here</Tooltip>} >
        <img src={IconDelete}/>
          </Whisper>
        </div>

       </Button>
        </Whisper>
                        )}
                      </ul>
                   
                    </div>

                    {item.CanEdit ? (
                      // <Link to={`/report/${item.encryptJSON}`} style={{ textDecoration: "none", color: "black" }} >
                      //   <h5>{item.name}</h5>
                      // </Link>

<div className="company_with_logo">
<div className="clogo">
  <img src={item.logo} />
</div>
  <Link to={`/report/${item.encryptJSON}`} style={{ textDecoration: "none", color: "black", width: 'calc(100% - 24px)', whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', }} >
    <h5 style={{marginTop:0, overflow: 'inherit',}}>{item.name}</h5>
  </Link>
</div>

                    ) : (
                      // <h5>{item.name}</h5>
                      <div className="company_with_logo">
<div className="clogo">
  <img src={item.logo} />
</div>
  <Link style={{ textDecoration: "none", color: "black", width: 'calc(100% - 24px)', whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', }} >
    <h5 style={{marginTop:0, overflow: 'inherit',}}>{item.name}</h5>
  </Link>
</div>

                    )}

                    <div className="task_date_row">
                      <p>{item.user_name}</p>

                      <p>
                        <span className="task_date">{item.updated_date}</span>
                      </p>
                    </div>
                    </div>
                    </Whisper>
                  </div>
                  
                ))
              )}           
            </div>

            {progressdata && progressdata.length > 0 && (
              <nav aria-label="..." className="clsPagination">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentPageProgress === 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        handleProgressPageChange(currentPageProgress - 1)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <i className="icon-prev"></i>
                    </a>
                  </li>
                  {[
                    ...Array(totalPagesProgress - startPageProgress + 1).keys(),
                  ].map((page) => (
                    <li
                      key={startPageProgress + page}
                      className={`page-item ${
                        currentPageProgress === startPageProgress + page
                          ? "active"
                          : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          handleProgressPageChange(startPageProgress + page)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {startPageProgress + page}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPageProgress === totalPagesProgress
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        handleProgressPageChange(currentPageProgress + 1)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <i className="icon-next"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            )}


</>
)}
          </div>

          <div className="task_col task_reviewing_col">
            <div className="task_col_header">
              <div className="task_title">
                <h4>
                  Reviewing <span>({reviewCount})</span>
                </h4>
              </div>
              <input
                type="text"
                id="searchInputReview"
                placeholder="Search"
                onChange={handleReviewingSearch}
                value={searchReviewInput}
                autocomplete="off"
              />
              <button
                className="btn_task_search"
                onClick={clearReviewSearchInput}
              ></button>
            </div>

            {reviewLoading && ( <Placeholder.Grid rows={20} columns={2} active style={{paddingTop:12}} /> )}

{/* Table */}
{!reviewLoading && (
<>
            <div className="task_col_inner">
              {reviewdata && reviewdata.length === 0 ? (
                <div className="clsNoData">No data available</div>
              ) : (
                reviewdata &&
                reviewdata.map((item, index) => (
                  // <div key={index} className="project_task_row project_task_reviewing" >
                    <div key={index} className={`project_task_row project_task_reviewing ${item.is_report_accessible == 1 ? "" : "disable_blur" }`} >
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover"  speaker={<Tooltip className={`${item.is_report_accessible == 1 ? "clsTooltipDisable" : "clsTooltipEnable" }`}>{item.accessible_msg}</Tooltip>}> 
                  <div>
                    <div className="project_task_head_row">
                      <ul className="ticker_locate_row">
                        <li className="ticker_task">{item.company_symbol}</li>
                        <li className="ticker_locate">
                          {item.exchange_symbol}
                        </li>
                        <li className="seprate" style={{width:1, height:13, alignSelf:'center', marginLeft:5, marginRight:5, backgroundColor:'#7D7D7D', }} />
                            <li className="ticker_country">
                            {item.country}
                            </li>
                      </ul>
                      <ul className="task_nav" style={{}}>
                          <li className="task_number">Q{item.quarter}-{item.year}</li>
                          {roleName === merlinAdmin && (
     <li>
      <div>
      <Whisper
          placement="right"
          trigger="click"
          open={openReviewPopover == item.id}
          //onOpen={() => handlePopoverOpen(item.id)}
          onClose={() => setOpenReviewPopover(null)}
          speaker={
            <Popover className="tooltip_form Rsuite_popover" preventOverflow>
              <h5>{item.name+' '+ item.exchange_symbol}</h5>
              <div className="tooltip_form_inner">
                <label>Select Analyst</label>
                <div>
                  <SelectPicker
                    {...props}
                    // onSelect={handleSelectPopover1}
                    data={seniorAssignData}
                    searchable={false}
                    placeholder="Analyst Name"
                    style={{ width: '100%' }}
                    menuClassName="rs-picker-select-menu rs-picker-popup selectPickerTMMain"
                    renderMenuItem={(label, item) => (
                      <div>
                        <img
                          src={item.avatar}
                          alt={item.label}
                          style={{ width: 44, height: 44, marginRight: 10 }}
                        />
                        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                          <div className="drop_title">{item.label}</div>
                          <div className="drop_designation">{item.role}</div>
                        </div>
                      </div>
                    )}
                    renderValue={(value, item) => item.label}
                    onChange={(value, item) => { if (value === null) { setSelectedUser1(null); } }}
                    onSelect={(value, item) => setSelectedUser1(item.userId)}
                  />
                </div>
                <div className='btn__group mt-5'>
                <a href="#" className="btn__cancel" onClick={() => setOpenReviewPopover(null)}> Cancel </a>
                 <a href="#" className="btn__assign" onClick={() => handleAssign1(selectedUser1, item.user_id, item.id,item.company_symbol,item.date,item.quarter,item.year)} >
                 Assign
                 </a>
                </div>
              </div>
            </Popover>
          }
        >
          {/* <Button onClick={handleOpenPopover1}>Open Popover1</Button> */}
          <Button className="btn_assign" data-tooltip-id="my-tooltip" onClick={() => handleReviewPopoverOpen(item.id)}>
         <div>
        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>You Can Assign Senior Analyst To This Report</Tooltip>} >
        <img src={IconAssign}/>
          </Whisper>
        </div>


       </Button>
        </Whisper>
     </div>
     </li>
  )}

{(roleName === merlinAdmin || roleName === merlinSeniorAnalyst) && (
<Whisper
          placement="right"
          trigger="click"
          open={openRejectPopover == item.id}
          //onOpen={() => handlePopoverOpen(item.id)}
          onClose={() => setOpenRejectPopover(null)}
          speaker={
            <Popover className="tooltip_form Rsuite_popover" preventOverflow>
              <div className="tooltip_form_inner text-center">
                                    <div style={{fontSize:20, color: "#141B3C",fontWeight: 600, lineHeight:1.3}}>
                                        Are you sure you want to <br />
                                        Reject this report ?
                                      </div>
                                      <p style={{color: '#696969',fontSize: 14,fontWeight: 500, marginTop: 15,}}>
                                      The report of {item.name} will be moved directly to the progress section.
                                      </p>

                                    <div className='btn__group mt-5'>
                                      <a href="#" className='btn__cancel' onClick={() => setOpenRejectPopover(null)}>Cancel</a>
                                      <a href="#" className='btn__assign'  onClick={() => handleRejectConfirm(item)}>Reject</a>
                                      
                                    </div>
                                </div>
            </Popover>
          }
        >
          {/* <Button onClick={handleOpenPopover1}>Open Popover1</Button> */}
          <Button className="btn_assign" data-tooltip-id="my-tooltip" onClick={() => handleRejectPopoverOpen(item.id)}>
        
         <div>
        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>You Can Reject Report From Here</Tooltip>} >
        <img src={IconReject}/>
          </Whisper>
        </div>

       </Button>
        </Whisper>
)}
                        </ul>


                  </div>

                    {roleName === merlinAdmin ||
                    roleName === merlinSeniorAnalyst ? (
                      // <Link to={`/report/${item.encryptJSON}`}  style={{ textDecoration: "none", color: "black" }}> <h5>{item.name}</h5> </Link>
                      <div className="company_with_logo">
<div className="clogo">
  <img src={item.logo} />
</div>
  <Link to={`/report/${item.encryptJSON}`} style={{ textDecoration: "none", color: "black", width: 'calc(100% - 24px)', whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', }} >
    <h5 style={{marginTop:0, overflow: 'inherit',}}>{item.name}</h5>
  </Link>
</div>

                    ) : (
                      // <h5>{item.name}</h5>
                      <div className="company_with_logo">
<div className="clogo">
  <img src={item.logo} />
</div>
  <Link style={{ textDecoration: "none", color: "black", width: 'calc(100% - 24px)', whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', }} >
    <h5 style={{marginTop:0, overflow: 'inherit',}}>{item.name}</h5>
  </Link>
                      </div>
                    )}

                    <div className="task_date_row2">
                      <ul class="viewer_analyst_list">
                        <li class="viewer_name"> {item.user_name}</li>
                        {/* <li class="analyst_name">{item.published_by}</li> */}
                        <li class="analyst_name">{item.reviewed_by}</li>
                      </ul>
                      <p>
                        <span className="task_date">{item.updated_date}</span>
                      </p>
                    </div>
                    </div>
                    </Whisper>
                  </div>
                ))
              )}
            </div>

            {reviewdata && reviewdata.length > 0 && (
              <nav aria-label="..." className="clsPagination">
                <ul className="pagination">
                  <li className={`page-item ${ currentPageReview === 1 ? "disabled" : "" }`} >
                    <a className="page-link" onClick={() => handleReviewingPageChange(currentPageReview - 1) } style={{ cursor: "pointer" }} >
                      <i className="icon-prev"></i>
                    </a>
                  </li>
                  {[ ...Array(totalPagesReview - startPageReview + 1).keys()].map((page) => (
                    <li key={startPageReview + page} className={`page-item ${ currentPageReview === startPageReview + page ? "active" : "" }`} >
                      <a className="page-link" onClick={() => handleReviewingPageChange(startPageReview + page) } style={{ cursor: "pointer" }} > {startPageReview + page} </a>
                    </li>
                  ))}
                  <li className={`page-item ${ currentPageReview === totalReviewPages ? "disabled" : "" }`} >
                    <a className="page-link" onClick={() => handleReviewingPageChange(currentPageReview + 1) } style={{ cursor: "pointer" }} >
                      <i className="icon-next"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            )}


</>
)}
          </div>

          <div className="task_col task_published_col">
            <div className="task_col_header">
              <div className="task_title">
                <h4>
                  Published <span>({publishCount})</span>
                </h4>
              </div>
              <input
                type="text"
                placeholder="Search"
                id="searchInputPublish"
                onChange={handlePublishSearch}
                value={searchPublishInput}
                autocomplete="off"
              />
              <button
                className="btn_task_search"
                onClick={clearPublishSearchInput}
              ></button>
            </div>

            {publishLoading && ( <Placeholder.Grid rows={20} columns={2} active style={{paddingTop:12}} /> )}

{/* Table */}
{!publishLoading && (
<>
            <div className="task_col_inner">
              {publishdata && publishdata.length === 0 ? (
                <div className="clsNoData">No data available</div>
              ) : (
                publishdata &&
                publishdata.map((item, index) => (
                  // <div key={index} className="project_task_row project_task_published" >
                    <div key={index} className={`project_task_row project_task_published`} >
                    
                    
                    <div className="project_task_head_row">
                      <ul className="ticker_locate_row">
                        <li className="ticker_task">{item.company_symbol}</li>
                        <li className="ticker_locate">
                          {item.exchange_symbol}
                        </li>
                        <li className="seprate" style={{width:1, height:13, alignSelf:'center', marginLeft:5, marginRight:5, backgroundColor:'#7D7D7D', }} />
                            <li className="ticker_country">
                            {item.country}
                            </li>
                      </ul>

                      <ul className="task_nav">
                        {/* task_tag_not_halal
                      task_tag_halal
                      task_tag_doubtful */}
                        <li className={`task_tag ${item.AAOIFI_status == 1 ? 'task_tag_halal' : item.AAOIFI_status == 2 ? 'task_tag_not_halal' : item.AAOIFI_status == 3 ? 'task_tag_doubtful' : ''}`} style={{width:75}}>
                        {item.AAOIFI_status_name === 'Non-Compliant' ? 'NCMPT' : item.AAOIFI_status_name === 'Compliant' ? 'CMPT' : item.AAOIFI_status_name === 'Doubtful' ? 'DBTF' : item.AAOIFI_status_name}
                        </li>
                        {/* <li>
                          <img src={iconReportUsers} style={{cursor:"pointer"}}    onClick={() => handleComplianceReport(item.encryptJSON)}  />
                        </li> */}
                        <Link to={`${apiEndPoint}/get_compliance_data?main_ticker=&stock_name=${encodeURIComponent(item.company_symbol)}`} target="_blank" style={{ textDecoration: "none", color: "black" }}>
                        <img src={iconReportUsers} style={{ cursor: "pointer", height:18 }} />
                       </Link>
                       <Link to={`${frontEndLink}/${item.company_symbol}`} target="_blank" style={{ textDecoration: "none", color: "black" }}>
                        <img src={Open_Link_Icon} style={{ cursor: "pointer", height:18 }} />
                       </Link>

                    

                      </ul>
                    </div>


                    {roleName === merlinAdmin || roleName === merlinSeniorAnalyst ? (
                      // <h5>                                                                                       
                      //   <Link style={{ textDecoration: "none", color: "black" }} to={`/report/${item.encryptJSON}`} >
                      //     <h5>{item.name}</h5>
                      //   </Link>
                      // </h5>
                      <div className="company_with_logo">
<div className="clogo">
  <img src={item.logo} />
</div>
  <Link to={`/report/${item.encryptJSON}`} style={{ textDecoration: "none", color: "black", width: 'calc(100% - 24px)', whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', }} >
    <h5 style={{marginTop:0, overflow: 'inherit',}}>{item.name}</h5>
  </Link>
</div>

                    ) : (
                      // <h5>{item.name}</h5>

                      <div className="company_with_logo">
<div className="clogo">
  <img src={item.logo} />
</div>
  <Link style={{ textDecoration: "none", color: "black", width: 'calc(100% - 24px)', whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', }} >
    <h5 style={{marginTop:0, overflow: 'inherit',}}>{item.name}</h5>
  </Link>
                      </div>

                    )}

                    <div className="task_date_row2">
                      <ul class="viewer_analyst_list">
                        <li class="viewer_name">{item.user_name}</li>
                        <li class="analyst_name">{item.published_by}</li>
                      </ul>
                      <p>
                        <span className="task_date">{item.updated_date}</span>
                      </p>
                    </div>
                    </div> 
                ))
              )}
            </div>

            {publishdata && publishdata.length > 0 && (
              <nav aria-label="..." className="clsPagination">
                <ul className="pagination">
                  <li className={`page-item ${ currentPagePublish === 1 ? "disabled" : "" }`} >
                    <a className="page-link" onClick={() => handlePublishPageChange(currentPagePublish - 1) } style={{ cursor: "pointer" }} >
                      <i className="icon-prev"></i>
                    </a>
                  </li>
                  {[
                    ...Array(totalPagesPublish - startPagePublish + 1).keys(), ].map((page) => (
                    <li key={startPagePublish + page} className={`page-item ${ currentPagePublish === startPagePublish + page ? "active" : "" }`} >
                      <a className="page-link" onClick={() => handlePublishPageChange(startPagePublish + page) } style={{ cursor: "pointer" }} >
                        {startPagePublish + page}
                      </a>
                    </li>
                  ))}
                  <li className={`page-item ${ currentPagePublish === totalPublishPages ? "disabled" : "" }`} >
                    <a className="page-link" onClick={() => handlePublishPageChange(currentPagePublish + 1) } style={{ cursor: "pointer" }} >
                      <i className="icon-next"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            )}

</>
)}
          </div>

        </div>
      </div>
    </>
  );
};

export default TaskManager;
